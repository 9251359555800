import React, {useCallback, useEffect, useState} from 'react'

import {isEmpty, values} from 'lodash'

import {ExpenseTypes} from 'avoapp-react-common/dist/constants'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {addFilters, removeFilter} from '../../../../redux/filters/filters'
import {FILTERS} from '../../../../redux/filters/spec'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {modalTypes, openModal} from '../../../../redux/modals'

import {datatablePageSize} from '../../../../utils/datatables'

import {AddExpenseModal} from '../../../../components/AddExpenseModal'
import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DatatableRowButtons} from '../../../../components/DatatableRowButtons'
import DeleteExpenseModal from '../../../../components/DeleteExpensePaymentModal/DeleteExpenseModal'
import {Toggle} from '../../../../components/Toggle'

import {columns} from './constants'

import './ExpensesList.scss'
import {ArrowDownTrayIcon} from '@heroicons/react/24/solid'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import fileDownload from 'js-file-download'

export const ExpensesList = ({
    match: {params: {projectID, clientID}},
    expenses,
    isLoading,
    totalPages,
    nextPage,
    previousPage,
    currentPage,
    filters,
    selectedEntityID,
    listExpenses,
    navigate,
    openAddExpenseModal,
    openDeleteExpenseModal,
    addFilters,
    hasCreateOption,
    removeFilter
}) => {
    const [searchFilter] = useState('')
    const [selectedExpense, setSelectedExpense] = useState(null)
    const [unpaidExpensesOnly, setUnpaidExpensesOnly] = useState(false)
    const [isPendingExport, setIsPendingExport] = useState(false)

    const handleFetchExpenses = useCallback((query = searchFilter, page = 1) => {
        const appliedFilters = {
            ...generateFiltersForAPI(filters),
            ...(projectID ? {project_id: projectID} : {}),
            ...(clientID ? {client_id: clientID} : {})
        }

        listExpenses(selectedEntityID, query, appliedFilters, page)
    }, [filters, listExpenses, projectID, clientID, searchFilter, selectedEntityID])

    useEffect(() => {
        handleFetchExpenses()
    }, [handleFetchExpenses, unpaidExpensesOnly])

    const handleChangePage = (page) => !isLoading && handleFetchExpenses(searchFilter, page)

    const onChangeExpensesOnly = (value) => {
        setUnpaidExpensesOnly(value)

        if(value === true) {
            addFilters({
                status: {
                    value: [ExpenseTypes.NOT_COVERED, ExpenseTypes.PARTIALLY_COVERED],
                    displayValue: null
                }
            })
        } else {
            removeFilter(FILTERS.expenses.status)
        }
    }

    return (
        <div className="page-info" style={{display: 'inline-block', width: '60%'}}>
            <Datatable
                title='Cheltuieli'
                data={expenses}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: expenseID, row: {original: expense}}) => (
                            <DatatableRowButtons
                                mainButtonTitle='Vezi'
                                onMainButtonClick={() => {
                                    navigate(`/expenses/${expenseID}`)
                                }}
                                onDeleteButtonClick={() => {
                                    setSelectedExpense(expense)
                                    openDeleteExpenseModal()
                                }}
                            />
                        ),
                        style: {
                            width: '75px'
                        }
                    }
                ]}
                loading={isLoading}
                headerButton={() => (
                    <>
                        <span style={{marginRight: '10px'}}>
                            <Button
                                loading={isPendingExport}
                                disabled={isEmpty(expenses) || isPendingExport}
                                title='Descarcă CSV'
                                icon={() => <ArrowDownTrayIcon/>}
                                onClick={async () => {
                                    setIsPendingExport(true)

                                    const appliedFilters = {
                                        ...generateFiltersForAPI(filters),
                                        ...(projectID ? {project_id: projectID} : {}),
                                        ...(clientID ? {client_id: clientID} : {})
                                    }

                                    const response = await performRequest(RESOURCES.expenses.listCSV(
                                        {...appliedFilters, entity_id: selectedEntityID, search: searchFilter}
                                    ))
                                    setIsPendingExport(false)

                                    if (response.status === 200) {
                                        toast.success('Fișierul CSV a fost generat.')
                                        fileDownload(response.data, 'export-cheltuieli.csv')
                                    } else {
                                        toast.error('A apărut o eroare la generarea raportului.')
                                    }
                                }}
                            />
                        </span>
                        {hasCreateOption && (
                            <Button title='Adaugă cheltuială' color='secondary' onClick={openAddExpenseModal} />
                        )}
                    </>
                )}
                customHeader={() =>
                    <div className='mt-5'>
                        <Toggle
                            label='Arată doar cheltuieli neîncasate'
                            checked={unpaidExpensesOnly}
                            onChange={onChangeExpensesOnly}
                        />
                    </div>
                }
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={currentPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
            />
            <AddExpenseModal projectId={projectID} />
            <DeleteExpenseModal selectedExpense={selectedExpense}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    expenses: values(state.expenses.data),
    isLoading: state.expenses.isLoading,
    totalPages: state.expenses.totalPages,
    nextPage: state.expenses.next,
    previousPage: state.expenses.previous,
    currentPage: state.expenses.current,
    filters: state.filters.expenses,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openAddExpenseModal: () => dispatch(openModal(modalTypes.ADD_EXPENSE)),
    openDeleteExpenseModal: () => dispatch(openModal(modalTypes.DELETE_EXPENSE)),
    navigate: (route) => dispatch(push(route)),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.expenses.name, filters)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.expenses.name, filter)),
    listExpenses: (entityID, search, filters, page) => dispatch(
        RESOURCES.expenses.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesList)
