import {Form, Formik} from 'formik'
import React, {useCallback, useMemo, useState} from 'react'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters} from '../../../../../../redux/filters/filters'
import {closeSlideover} from '../../../../../../redux/slideovers'

import {toApiDateFormat} from '../../../../../../utils'
import {debounceWait} from '../../../../../../utils/constants'
import {useDebouncedEffect} from '../../../../../../utils/hooks'
import {isCompletedOptions} from '../../constants'

import {Button} from '../../../../../../components/Button'
import {DatePicker} from '../../../../../../components/DatePicker'
import {ErrorsList} from '../../../../../../components/ErrorComponents'
import {Select} from '../../../../../../components/Select'

import '../../../../../../assets/scss/SlideoverForms.scss'

export const FilterTasksForm = ({
    filters,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    projects,
    isLoadingProjects,
    searchProjects,
    addFilters,
    filterTasks,
    closeSlideover
}) => {
    const [projectsQuery, setProjectsQuery] = useState('')

    const handleFetchProjects = useCallback(() => { searchProjects(projectsQuery) }, [projectsQuery, searchProjects])

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    const handleChangeProjectsSearchField = useCallback((value) => { setProjectsQuery(value) }, [])

    const handleFilter = useCallback((filters) => {
        addFilters(filters)
        filterTasks()
        closeSlideover()
    }, [addFilters, closeSlideover, filterTasks])

    const initialProject = useMemo(() => {
        return _.find(projects, (project) => filters.project.value === project.id)
    }, [filters.project.value, projects])

    const initialStatus = useMemo(() => {
        return _.find(_.values(isCompletedOptions), ['value', filters.isCompleted.value])
    }, [filters.isCompleted.value])

    const handleChangeDate = useCallback((field, date, setFieldValue) => {
        if(!_.isNil(date)) {
            setFieldValue(field, new Date(date))
        } else {
            setFieldValue(field, date)
        }
    }, [])

    return (
        <div className="slideover-form-container">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    project: initialProject,
                    isCompleted: initialStatus,
                    stopLTE: filters.stopLTE?.value ? new Date(filters.stopLTE.value) : null,
                    startGTE: filters.startGTE?.value ? new Date(filters.startGTE.value) : null
                }}
                onSubmit={(values) => {
                    const localFormatDate = (date) => {
                        if(_.isNil(date) || date === '') {
                            return null
                        }

                        return lightFormat(new Date(date), 'dd/MM/yyyy')
                    }

                    const filtersData = {
                        project: {
                            value: values.project?.id || '',
                            displayValue: values.project?.name || ''
                        },
                        isCompleted: {
                            value: values.isCompleted?.value || null,
                            displayValue: values.isCompleted?.label || ''
                        },
                        startGTE: {
                            value: toApiDateFormat(values.startGTE),
                            displayValue: localFormatDate(values.startGTE)
                        },
                        stopLTE: {
                            value: toApiDateFormat(values.stopLTE),
                            displayValue: localFormatDate(values.stopLTE)
                        }
                    }

                    handleFilter(filtersData)
                }}
            >
                {({setFieldValue, handleSubmit, values}) => (
                    <>
                        <Form className='slideover-form'>
                            <Select
                                label='Proiect'
                                value={values.project}
                                options={projects}
                                onChange={(e) => setFieldValue('project', e)}
                                onInputChange={handleChangeProjectsSearchField}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                loading={isLoadingProjects}
                                name='projects'
                                errors={fieldErrors}
                                fullWidth
                            />
                            <Select
                                label='Status'
                                value={values.isCompleted}
                                options={_.values(isCompletedOptions)}
                                onChange={(e) => setFieldValue('isCompleted', e)}
                                name='isCompleted'
                                errors={fieldErrors}
                                isClearable
                                fullWidth
                            />
                            <DatePicker
                                label='Dată start'
                                value={values.startGTE}
                                onChange={(date) => handleChangeDate('startGTE', date, setFieldValue)}
                                fullWidth
                            />
                            <DatePicker
                                label='Dată stop'
                                value={values.stopLTE}
                                onChange={(date) => handleChangeDate('stopLTE', date, setFieldValue)}
                                fullWidth
                            />
                        </Form>
                        <div className="buttons-container">
                            <Button
                                title='Aplică'
                                onClick={handleSubmit}
                                loading={isLoading}
                                type='submit'
                                fullWidth
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => ({
    filters: state.filters.tasks,
    isLoading: state.tasks.isLoading,
    fieldErrors: state.tasks.fieldErrors,
    nonFieldErrors: state.tasks.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    projects: _.values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search)),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.tasks.name, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterTasksForm)