
export const debounceWait = 450

export const RON_CURRENCY_VALUE = 'RON'

export const ronCurrency = {value: RON_CURRENCY_VALUE, label: 'RON'}

export const CANCELED_STATE = 'canceled'

export const MDSubscriptionSources = {
    PORTAL: {label: 'Portal Just', value: 'portal_just'},
    MANUAL: {label: 'Dosar manual', value: 'manual'}
}

export enum Ordering {
    ASC = 'asc',
    DESC = 'desc'
}
