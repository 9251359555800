import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {ArrowDownTrayIcon} from '@heroicons/react/24/outline'

import {lightFormat, startOfYear} from 'date-fns'
import fileDownload from 'js-file-download'
import {isEmpty, isNil, orderBy, toNumber} from 'lodash'
import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {addFilters, removeFilter} from '../../../../redux/filters/filters'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {modalTypes, openModal} from '../../../../redux/modals'

import {toApiDateFormat} from '../../../../utils'
import {CANCELED_STATE, debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {useDebouncedEffect} from '../../../../utils/hooks'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DeleteInvoiceButton} from '../../../../components/DeleteInvoiceButton'
import {DeleteInvoiceModal} from '../../../../components/DeleteInvoiceModal'
import {Loader} from '../../../../components/Loader'
import {Toggle} from '../../../../components/Toggle'

import {FilterInvoicesForm} from '../forms'

import './InvoicesList.scss'
import InvoiceExternalSyncIcon from '../../../InvoiceDetails/partials/InvoiceExternalSyncIcon/InvoiceExternalSyncIcon'
import {
    useCancelSmartBillInvoice
} from '../../../../mutations/rest'
import {externalSyncActions, externalSyncStates} from '../../constants'
import {InvoiceExternalSyncResolveModal} from '../../../../components/InvoiceExternalSyncResolveModal'
import {TableLink} from '../../../../components/TableLink'

export const InvoicesList = ({
    invoices,
    isLoading,
    totalPages,
    nextPage,
    previousPage,
    currentPage,
    listInvoices,
    selectedEntityID,
    filters,
    addFilters,
    removeFilter,
    invoicesReports,
    isLoadingInvoicesReports,
    listInvoicesReports,
    usersPreferences,
    cancelInvoice,
    openDeleteInvoiceModal,
    openInvoiceExternalSyncResolveModal,
    openSyncInvoiceModal
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [onlyNonCollected, setOnlyNonCollected] = useState(false)
    const [isPendingExport, setIsPendingExport] = useState(false)

    const onSmartBillFinished = useCallback((data, action) => {
        const {errors, status} = data

        if (_.includes([403, 418], status)) {
            // SmartBill is not enabled for this entity. Missing API key or action not needed.
            return
        }

        if (status >= 400) {
            const errorMessage = _.get(
                errors, 'non_field_errors.0.message', 'A apărut o eroare la sincronizarea cu SmartBill'
            )
            openSyncInvoiceModal(selectedInvoice.id, action, errorMessage)
        } else {
            toast('Factura a fost sincronizată cu succes în SmartBill', {type: 'success'})
        }
    }, [selectedInvoice, openSyncInvoiceModal])

    const {mutateAsync: cancelSmartBillInvoice} = useCancelSmartBillInvoice({
        onSuccess: (data, variables, context) => {onSmartBillFinished(data, externalSyncActions.CANCEL)}
    })

    useEffect(() => {
        const dateStart = startOfYear(new Date())

        addFilters({
            dateGTE: {
                value: toApiDateFormat(dateStart),
                displayValue: lightFormat(dateStart, 'dd/MM/yyyy')
            }
        })
    }, [addFilters])

    const handleFetchInvoices = useCallback((query = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)
        const preparedFilters = {
            ...(onlyNonCollected && {state: 'unpaid'}),
            ...appliedFilters
        }

        listInvoices(selectedEntityID, query, preparedFilters, page)
    }, [filters, listInvoices, searchFilter, selectedEntityID, onlyNonCollected])

    useDebouncedEffect(handleFetchInvoices, [searchFilter, onlyNonCollected], debounceWait)

    const handleListInvoicesReports = useCallback((query = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)
        const preparedFilters = {
            ...(onlyNonCollected && {state: 'unpaid'}),
            ...appliedFilters
        }

        listInvoicesReports(selectedEntityID, query, preparedFilters)
    }, [filters, listInvoicesReports, searchFilter, selectedEntityID, onlyNonCollected])

    useDebouncedEffect(handleListInvoicesReports, [searchFilter, onlyNonCollected], debounceWait)

    const handleChangePage = (page) => !isLoading && handleFetchInvoices(searchFilter, page)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const handleDeleteInvoice = useCallback(async (invoice) => {
        setSelectedInvoice(invoice)
        if(invoice.deletion_prevented) {
            const {status: cancelHTTPStatus} = await cancelSmartBillInvoice(invoice)
            if(cancelHTTPStatus === 400) {
                return
            }
            cancelInvoice(invoice.id)
        } else {
            openDeleteInvoiceModal()
        }
        // eslint-disable-next-line
    }, [cancelInvoice, openDeleteInvoiceModal])

    const getCanceledInvoiceClassName = useCallback((invoice) => {
        if(invoice.state === 'canceled') {
            return 'invoice-canceled'
        }

        return ''
    }, [])

    const invoicesColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'issue_date',
                Cell: ({value: issueDate, row:{original: invoice}}) => (
                    <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                        {issueDate ? lightFormat(new Date(issueDate), 'dd/MM/yyyy') : '-'}
                    </span>
                )
            },
            {
                Header: 'Serie și număr',
                accessor: 'series_id',
                Cell: ({_value, row: {original: invoice}}) => (
                    <TableLink to={`/invoices/${invoice.id}`}>
                        <span
                            className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {invoice.series_name} {invoice.series_number}
                        </span>
                    </TableLink>
                )
            },
            {
                Header: 'Client',
                accessor: 'client',
                Cell: ({value: client, row: {original: invoice}}) => (
                    <TableLink to={`/clients/${client.id}`}>
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {client ? client.name : '-'}
                        </span>
                    </TableLink>
                ),
                style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value: project, row: {original: invoice}}) => (
                    <TableLink to={`/projects/${project.id}`}>
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {project ? project.name : '-'}
                        </span>
                    </TableLink>
                ),
                style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
            },
            {
                Header: 'Suma',
                accessor: 'total_with_VAT',
                Cell: ({value: total, row: {original: invoice}}) => {
                    return (
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {total} {invoice.currency}
                        </span>
                    )
                }
            },
            {
                Header: 'De încasat',
                accessor: 'total_unpaid',
                Cell: ({value: total, row: {original: invoice}}) => {
                    return (
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {total} {invoice.currency}
                        </span>
                    )
                }
            },

            ...usersPreferences.has_smartbill_integration ? [{
                Header: 'SmartBill',
                accessor: 'sync_state_smartbill',
                Cell: ({value, row: {original: invoice}}) => (
                    <span onClick={() => {
                        setSelectedInvoice(invoice)

                        if (value === externalSyncStates.MANUAL_NEEDS_UPDATE) {
                            openInvoiceExternalSyncResolveModal()
                        }
                    }}>
                        <InvoiceExternalSyncIcon
                            state={value}
                        />
                    </span>
                ),
                style: {
                    width: '55px'
                }
            }] : [],
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({value: invoiceID, row: {original: invoice}}) => (
                    <div className='datatable-row-buttons-container'>
                        <Link to={`/invoices/${invoiceID}`}>
                            <Button title='Vezi' size='small' />
                        </Link>
                        <DeleteInvoiceButton
                            invoice={invoice}
                            onClick={() => handleDeleteInvoice(invoice)}
                        />
                    </div>
                )
            }
        ]
    }, [usersPreferences, getCanceledInvoiceClassName, openInvoiceExternalSyncResolveModal, handleDeleteInvoice])

    return (
        <div className="page-info">
            <Datatable
                title='Facturi fiscale'
                data={invoices}
                columns={invoicesColumns}
                customHeader={() =>
                    <div className='custom-invoices-header'>
                        <Toggle
                            checked={onlyNonCollected}
                            label='Arată doar facturile neîncasate'
                            onChange={() => setOnlyNonCollected((prevState) => !prevState)}
                        />
                        <div className="total-filtered-container">
                            {!isNil(invoicesReports) && !isEmpty(invoicesReports) && !isLoadingInvoicesReports ? (
                                <>
                                    <div>
                                        Total sume facturate:
                                        <span className="total-sum">{invoicesReports.RON.total_with_VAT} (RON)</span>
                                        {toNumber(invoicesReports.EUR.total_with_VAT) > 0 && (
                                            <>
                                                , { }
                                                <span
                                                    className="total-sum">{invoicesReports.EUR.total_with_VAT} (EUR)
                                                </span>
                                            </>
                                        )}

                                    </div>
                                    <div>
                                        Total sume neîncasate:
                                        <span className="total-sum">{invoicesReports.RON.total_unpaid} (RON)</span>
                                        {toNumber(invoicesReports.EUR.total_with_VAT) > 0 && (
                                            <>
                                                , { }
                                                <span
                                                    className="total-sum">{invoicesReports.EUR.total_unpaid} (EUR)
                                                </span>
                                            </>
                                        )}

                                    </div>
                                </>
                            ) : (
                                <Loader size='small'/>
                            )}
                        </div>
                    </div>
                }
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={currentPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                filterable
                filters={filters}
                filtersForm={() => (
                    <FilterInvoicesForm
                        filterInvoices={() => {
                            handleFetchInvoices()
                            handleListInvoicesReports()
                        }}
                    />
                )}
                handleRemoveFilter={(filter) => {
                    removeFilter(filter)
                    handleFetchInvoices()
                    handleListInvoicesReports()
                }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută facturi'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
                searchContainerButton={() => (
                    <Button
                        loading={isPendingExport}
                        disabled={isEmpty(invoices) || isPendingExport}
                        title='Descarcă CSV'
                        icon={() => <ArrowDownTrayIcon/>}
                        onClick={async () => {
                            setIsPendingExport(true)

                            const preparedFilters = {
                                ...generateFiltersForAPI(filters), search: searchFilter
                            }

                            const response = await performRequest(RESOURCES.invoices.listCSV({
                                entity_id: selectedEntityID,
                                ...(onlyNonCollected && {state: 'unpaid'}),
                                ...preparedFilters
                            }))
                            setIsPendingExport(false)

                            if (response.status === 200) {
                                toast.success('Fișierul CSV a fost generat.')
                                fileDownload(response.data, 'export-facturi.csv')
                            } else {
                                toast.error('A apărut o eroare la generarea raportului.')
                            }
                        }}
                    />
                )}
            />
            <DeleteInvoiceModal selectedInvoice={selectedInvoice}/>
            <InvoiceExternalSyncResolveModal
                selectedInvoice={selectedInvoice} reloadData={() => {handleFetchInvoices()}}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    invoices: orderBy(state.invoices.data, ['issue_date', 'created'], ['desc', 'desc']),
    isLoading: state.invoices.isLoading,
    currentPage: state.invoices.current,
    nextPage: state.invoices.next,
    previousPage: state.invoices.previous,
    totalPages: state.invoices.totalPages,
    filters: state.filters.invoices,
    invoicesReports: state.invoicesReports.data,
    isLoadingInvoicesReports: state.invoicesReports.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID,
    usersPreferences: state.usersPreferences.data
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteInvoiceModal: () => dispatch(openModal(modalTypes.DELETE_INVOICE)),
    openInvoiceExternalSyncResolveModal: () => dispatch(openModal(modalTypes.INVOICE_EXTERNAL_MANUAL_RESOLVE_MODAL)),
    navigate: (route) => dispatch(push(route)),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.invoices.name, filters)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.invoices.name, filter)),
    openSyncInvoiceModal: (invoiceID, action, errorMessage) => dispatch(
        openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {invoiceID, action, errorMessage})
    ),
    listInvoices: (entityID, search, filters, page) => dispatch(
        RESOURCES.invoices.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    ),
    listInvoicesReports: (entityID, search, filters, page) => dispatch(
        RESOURCES.invoicesReports.list(
            {...filters, entity_id: entityID, search: search},
            // overwriteData
            true
        )
    ),
    cancelInvoice: (invoiceID) => dispatch(RESOURCES.invoices.update({state: CANCELED_STATE}, invoiceID))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesList)
