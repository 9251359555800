import {lightFormat} from 'date-fns'
import React from 'react'
import {CheckCell} from '../../../../components/CheckCell'
import {ProgressPie} from '../../../../components/ProgressPie'

export const columns = [
    {
        Header: 'Data',
        accessor: 'date',
        Cell: ({value: start}) => start ? lightFormat(new Date(start), 'dd/MM/yyyy') : '-',
        style: {
            width: '75px'
        }
    },
    {
        Header: 'Suma',
        accessor: 'amount',
        Cell: ({value, row: {original}}) => `${value} ${original.currency}`
    },
    {
        Header: 'Descriere',
        accessor: 'description'
    },
    {
        Header: '',
        accessor: 'uncovered_allocation',
        Cell: ({value, row: {original}}) => (
            value === null ? <CheckCell/> : <ProgressPie
                percentage={100 - (value.amount_RON/original.amount_RON) * 100}
            />
        ),
        style: {
            width: '55px'
        }
    }
]

export const isCompletedOptions = {
    FALSE: {
        label: 'Active',
        value: false
    },
    TRUE: {
        label: 'Finalizate',
        value: true
    }
}
