import React, {useCallback, useMemo, useState} from 'react'

import {TrashIcon} from '@heroicons/react/24/outline'
import {keepPreviousData} from '@tanstack/react-query'

import _ from 'lodash'

import {setCurrent as setCurrentSubscription} from 'avoapp-react-common/dist/redux/bpiSubscriptions'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../../redux/modals'

import {Button} from '../../../../../components/Button'
import {Datatable} from '../../../../../components/Datatable'

import {useQueryResourceList} from '../../../../../queries/rest'
import {debounceWait} from '../../../../../utils/constants'
import {useDebouncedState} from '../../../../../utils/hooks'

import {bpiColumns} from '../constants'

import {DeleteBpiSubscriptionModal} from '../../../../../components/DeleteBpiSubscriptionModal'
import {BPINotificationsSection} from '../../../../MonitorBPI/partials'
import {Link} from 'react-router-dom'

export const ExternalDataMD = ({
    selectedEntityID,
    setCurrentSubscription,
    openedModal,
    openModal,
    match: {params: {clientID}}
}) => {
    const [searchFilter, setSearchFilter] = useDebouncedState('', debounceWait)
    const [page, setPage] = useState(1)

    const filters = useMemo(() => ({
        search: searchFilter,
        client_id: clientID,
        entity_id: selectedEntityID,
        page_size: 15,
        page
    }), [page, clientID, searchFilter, selectedEntityID])

    const {data: subscriptionsResult, isFetching} = useQueryResourceList(
        RESOURCES.bpiSubscriptions,
        filters,
        {placeholderData: keepPreviousData},
        //we want to refetch the data when the add or delete modal closes
        _.isNil(openedModal)
    )

    const handleChangeSearchField = useCallback((value) => setSearchFilter(value), [setSearchFilter])

    const handleChangePage = useCallback((page) => setPage(page), [])

    if(_.isNil(subscriptionsResult) ) {
        return <React.Fragment />
    }

    return (
        <div className='page-project-show flex gap-4'>
            <div className="w-2/5 shrink-0">
                <Datatable
                    title='Listă părți monitorizate'
                    data={subscriptionsResult.data.results}
                    columns={[
                        ...bpiColumns,
                        {
                            Header: 'Acțiuni',
                            accessor: 'id',
                            Cell: ({row}) => (
                                <div className='datatable-row-buttons-container'>
                                    <Link to={`/projects/${row.original.project.id}/external-data/monitor-bpi`}>
                                        <Button title='Vizualizare' size='small' />
                                    </Link>
                                    <Button
                                        icon={() => <TrashIcon />}
                                        onClick={() => {
                                            setCurrentSubscription(row.original)
                                            openModal(modalTypes.DELETE_BPI_SUBSCRIPTION)
                                        }}
                                        size='small'
                                        color='red'
                                    />
                                </div>
                            ),
                            disableSortBy: true
                        }
                    ]}
                    loading={isFetching}
                    previousPage={subscriptionsResult.data?.pages?.previous}
                    currentPage={subscriptionsResult.data?.page}
                    nextPage={subscriptionsResult.data?.pages?.next}
                    totalPages={subscriptionsResult.data?.number_of_pages}
                    onChangePage={(page) => handleChangePage(page)}
                    searchable
                    searchValue={searchFilter}
                    searchPlaceholder='Caută surse de date'
                    onSearch={(event) => handleChangeSearchField(event.target.value)}
                />
                <DeleteBpiSubscriptionModal />
            </div>
            <BPINotificationsSection clientID={clientID} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    openedModal: state.modals.type
})

const mapDispatchToProps = dispatch => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    setCurrentSubscription: (subscription) => dispatch(setCurrentSubscription(subscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataMD)