import React, {useMemo} from 'react'

import {endOfDay, startOfDay} from 'date-fns'

// @ts-ignore
import {HearingsSection} from '../../../pages/MonitorDosare/partials/HearingsSection/HearingsSection'
import {Ordering} from '../../../utils/constants'

export default function MonitorDosareHearings() {
    const todayInterval = useMemo(() => {
        return {
            start: startOfDay(new Date()),
            stop: endOfDay(new Date())
        }
    }, [])

    const futureInterval = useMemo(() => {
        return {
            start: new Date()
        }
    }, [])

    const pastInterval = useMemo(() => {
        return {
            stop: new Date()
        }
    }, [])

    return (
        <div className='external-data-hearings-section'>
            <HearingsSection
                sectionTitle='Termene astăzi'
                showSubscriptionNumber={true}
                timeInterval={todayInterval}
                ordering={Ordering.ASC}
            />
            <HearingsSection
                sectionTitle='Termene în viitor'
                showSubscriptionNumber={true}
                timeInterval={futureInterval}
                ordering={Ordering.ASC}
            />
            <HearingsSection
                sectionTitle='Termene din trecut'
                showSubscriptionNumber={true}
                timeInterval={pastInterval}
                ordering={Ordering.DESC}
            />
        </div>
    )
}
