import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {debounce, isEmpty, isNull, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {uploadSignedFile} from 'avoapp-react-common/dist/redux/empowerments'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {removeFilter} from '../../../../redux/filters/filters'

import {columns} from './constants'
import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import {modalTypes, openModal} from '../../../../redux/modals'
import {DeleteEmpowermentModal} from '../../../../components/DeleteEmpowermentModal'

// import FilterEmpowermentsForm from '../forms/FilterEmpowermentsForm'

export const EmpowermentsList = ({
    empowerments,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    openDeleteEmpowermentModal,
    removeFilter,
    listEmpowerments,
    selectedEntityID
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedEmpowerment, setSelectedEmpowerment] = useState(null)

    const handleFetchEmpowerments = (search = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listEmpowerments(search, appliedFilters, page)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {handleFetchEmpowerments()}, [])

    const debounceSearchEmpowerments = debounce((search) => handleFetchEmpowerments(search), debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchEmpowerments(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchEmpowerments(searchFilter, page)

    const handleDropSignedFile = async (selectedFile, empowermentID) => {
        if(!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.empowerments.update({signed_file: fileURL}, empowermentID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    return (
        <>
            <Datatable
                // title='Listă împuterniciri'
                data={empowerments}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: empowermentID, row: {original: empowerment}}) => {
                            // this return is needed for indentaion
                            return (
                                <div className='flex gap-2 items-center'>
                                    <Link to={`/contracts/empowerments/${empowermentID}/preview`}>
                                        <Button title='Vizualizare' variant='text' size='small' />
                                    </Link>
                                    <Dropdown
                                        title='Descarcă'
                                        disabled={
                                            isNull(empowerment.file_meta?.url_public) && isNull(empowerment.signed_file)
                                        }
                                        items={[
                                            {
                                                customContent: () =>
                                                    empowerment.file_meta?.url_public ? (
                                                        <a
                                                            href={empowerment.file_meta.url_public}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                            className='avo-dropdown-menu-item'
                                                        >
                                                            Descarcă
                                                        </a>
                                                    ) : (
                                                        <React.Fragment />
                                                    )
                                            },
                                            {
                                                customContent: () =>
                                                    empowerment.signed_file ? (
                                                        <a
                                                            href={empowerment.signed_file}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                            className='avo-dropdown-menu-item'
                                                        >
                                                            Descarcă fișier semnat
                                                        </a>
                                                    ) : (
                                                        <React.Fragment />
                                                    )
                                            }
                                        ]}
                                        variant='text'
                                        color='secondary'
                                        size='small'
                                    />
                                    <Dropdown
                                        title='Încarcă'
                                        items={[
                                            {
                                                customContent: () => (
                                                    <SignedFileDropzone
                                                        onChange={(selectedFile) =>
                                                            handleDropSignedFile(selectedFile, empowermentID)
                                                        }
                                                    />
                                                )
                                            }
                                        ]}
                                        variant='text'
                                        color='orange'
                                        size='small'
                                    />
                                    <Button
                                        title='Șterge'
                                        onClick={() => {
                                            setSelectedEmpowerment(empowerment)
                                            openDeleteEmpowermentModal()
                                        }}
                                        size='small'
                                        color='red'
                                        variant='text'
                                    />
                                </div>
                            )
                        }
                    }
                ]}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                // filterable
                // filters={filters}
                // filtersForm={() => <FilterEmpowermentsForm filterEmpowerments={handleFetchEmpowerments} />}
                // handleRemoveFilter={(filter) => {
                //     removeFilter(filter)
                //     handleFetchEmpowerments()
                // }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută împuterniciri'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <DeleteEmpowermentModal empowerment={selectedEmpowerment} />
        </>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    empowerments: orderBy(state.empowerments.data, [(e) => e.signed_date || '', 'id'], ['desc', 'desc']),
    isLoading: state.empowerments.isLoading,
    totalPages: state.empowerments.totalPages,
    nextPage: state.empowerments.next,
    previousPage: state.empowerments.previous,
    currentPage: state.empowerments.current,
    filters: state.filters.empowerments
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteEmpowermentModal: () => dispatch(openModal(modalTypes.DELETE_EMPOWERMENT)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.empowerments.name, filter)),
    uploadSignedFile: (empowermentData, empowermentID) => dispatch(uploadSignedFile(empowermentData, empowermentID)),
    listEmpowerments: (search, filters, page) =>
        dispatch(
            RESOURCES.empowerments.list(
                {
                    ...filters,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpowermentsList)
