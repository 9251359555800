import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {isNull, orderBy} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters, removeFilter} from '../../../../redux/filters/filters'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'

import {toApiDateFormat, toApiTimeFormat} from '../../../../utils'
import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {useDebouncedEffect} from '../../../../utils/hooks'
import {columns, isCompletedOptions} from './constants'

import {AddTaskModal} from '../../../../components/AddTaskModal'
import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {Toggle} from '../../../../components/Toggle'

import {FilterTasksForm} from './partials'

import './TasksList.scss'

export const TasksList = ({
    tasks,
    isLoading,
    totalPages,
    nextPage,
    previousPage,
    currentPage,
    filters,
    entityProfileID,
    selectedEntityID,
    addFilters,
    removeFilter,
    listTasks,
    updateTask
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [myTasks, setMyTasks] = useState(true)

    const handleFetchTasks = (query = searchFilter, page = 1) => {
        let appliedFilters = generateFiltersForAPI(filters)

        if(myTasks) {
            appliedFilters = {...appliedFilters, owner_id: entityProfileID}
        }

        listTasks(selectedEntityID, query, appliedFilters, page)
    }

    useDebouncedEffect(handleFetchTasks, [searchFilter, myTasks], debounceWait)

    useEffect(() => {
        addFilters({
            isCompleted: {
                value: isCompletedOptions['FALSE'].value,
                displayValue: isCompletedOptions['FALSE'].label
            }
        })
    }, [addFilters])

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const handleChangePage = (page) => !isLoading && handleFetchTasks(searchFilter, page)

    const handleChangeCompletion = (task) => {
        let taskData = {completion: null}

        if(isNull(task.completion)) {
            const datetime = `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`

            taskData = {completion: datetime}
        }

        updateTask(taskData, task.id)
    }

    return (
        <div className="page-info">
            <Datatable
                data={tasks}
                columns={[
                    ...columns,
                    {
                        Header: '',
                        accessor: 'id',
                        Cell: ({value: taskID, row: {original: task}}) => (
                            <div className='tasks-list-datatable-action-buttons-column'>
                                <Link to={`/tasks/${taskID}`} >
                                    <Button title='Vizualizare' variant='text' size='small' />
                                </Link>
                                <Button
                                    title={isNull(task.completion) ? 'Finalizează' : 'Activează'}
                                    color={isNull(task.completion) ? 'secondary' : 'gray'}
                                    onClick={() => handleChangeCompletion(task)}
                                    variant='text'
                                    size='small'
                                />
                            </div>
                        )
                    }
                ]}
                customHeader={() =>
                    <div className='mt-5'>
                        <Toggle label='Arată doar sarcinile mele' checked={myTasks} onChange={setMyTasks}/>
                    </div>
                }
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={currentPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                filterable
                filters={filters}
                filtersForm={() => <FilterTasksForm filterTasks={handleFetchTasks} />}
                handleRemoveFilter={(filter) => {
                    removeFilter(filter)
                    handleFetchTasks()
                }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută sarcini'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <AddTaskModal />
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        tasks: orderBy(state.tasks.data, ['start', 'created'], ['desc', 'desc']),
        isLoading: state.tasks.isLoading,
        totalPages: state.tasks.totalPages,
        nextPage: state.tasks.next,
        previousPage: state.tasks.previous,
        currentPage: state.tasks.current,
        filters: state.filters.tasks,
        entityProfileID: state.localConfigs.entityProfileID,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch) => ({
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.tasks.name, filter)),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.tasks.name, filters)),
    updateTask: (taskData, taskID) => dispatch(RESOURCES.tasks.update(taskData, taskID)),
    listTasks: (entityID, search, filters, page) => dispatch(
        RESOURCES.tasks.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(TasksList)