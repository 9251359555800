import React, {useCallback, useMemo} from 'react'

// @ts-ignore
import {Link} from 'react-router-dom'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {Notification} from 'types/api'

// @ts-ignore
import {connect} from 'react-redux'
// @ts-ignore
import {closeSlideover, slideoverTypes} from '../../../../redux/slideovers'
// @ts-ignore
import {changeTimeFormat} from 'avoapp-react-common/dist/utils'

// @ts-ignore
import {Slideover} from '../../../../components/Slideover'
// @ts-ignore
import {Button} from '../../../../components/Button'

type MDNotificationDetailsProps = {
    open: boolean
    notification: Notification
    closeSlideover: () => void
}

export const MDNotificationDetails = ({open, notification, closeSlideover}: MDNotificationDetailsProps) => {
    const subtitle = useMemo(() => {
        switch (notification.type) {
            case 'litigation':
            case 'upcoming_hearing':
                return `La dosarul ${notification.source.litigation_number}:`

            case 'party':
                return `Pentru clientul ${notification.source.client_name}:`

            default:
                console.log('notification: ', notification)
                return ''
        }
    }, [notification])

    const getMessageFromEvent = useCallback((event, entity) => {
        switch (event) {
            case 'new_solution':
                return (
                    <p className="notification-details-message">
                        - S-a adăugat o soluție nouă:{' '}
                        {entity.new_solution || entity.solution}.
                    </p>
                )
            case 'new_hearing':
                return (
                    <p className="notification-details-message">
                        - S-a adăugat o ședință nouă pe{' '}
                        pe {lightFormat(new Date(entity.date), 'dd/MM/yyyy')}{' '}
                        ora {changeTimeFormat(entity.time)}.
                    </p>
                )
            case 'new_solution_and_hearing':
                return (
                    <>
                        <p className="notification-details-message">
                            - S-a adăugat o ședință nouă{' '}
                            pe {lightFormat(new Date(entity.date), 'dd/MM/yyyy ')}{' '}
                            ora {changeTimeFormat(entity.time)}.
                        </p>
                        <p className="notification-details-message">
                            - S-a adăugat o soluție nouă: {entity.new_solution ? entity.new_solution : entity.solution}.
                        </p>
                    </>
                )
            case 'new_appeal':
                return (
                    <p className="notification-details-message">
                        -  S-a adăugat o cale nouă de atac la "{entity.court}".
                    </p>
                )
            case 'changed_hearing_hour':
                return (
                    <p className="notification-details-message">
                        -  S-a schimbat ora ședinței: "{changeTimeFormat(entity.time)}".
                    </p>
                )
            case 'new_litigation':
                return (
                    <>
                        <p className="notification-details-message">
                            - {entity.length === 1 ? 'S-a adăugat un dosar nou: ' : 'S-au adăugat dosare noi: '}
                        </p>
                        {entity.map((litigation: any, index: number) => {
                            return (
                                <div key={index}>
                                    <p className="notification-details-message litigation-number-text">
                                        - {litigation.number}:
                                    </p>
                                    <p className="notification-details-message party-text">
                                        Părți:
                                    </p>
                                    {litigation.parties.map((party: any, index: number) => (
                                        <p key={index} className="notification-details-message party-value">
                                            - {party}
                                        </p>
                                    ))}
                                </div>
                            )
                        })}
                    </>
                )
            case 'upcoming_hearing':
                return (
                    <p className="notification-details-message">
                        - Aveți o ședință{' '}
                        în data de {lightFormat(new Date(entity.date), 'dd/MM/yyyy')}{' '}
                        la ora {changeTimeFormat(entity.time)}.
                    </p>
                )
            default:
                console.log('UNKNOWN EVENT', event)
                break
        }
    }, [])

    const sourceLink = useMemo(() => {
        let sourceName, sourceId

        if(!_.isNil(notification.source?.project_id)) {
            sourceName = 'projects'
            sourceId = notification.source?.project_id
        }

        if(!_.isNil(notification.source?.client_id)) {
            sourceName = 'clients'
            sourceId = notification.source?.client_id
        }

        return `/${sourceName}/${sourceId}/external-data/monitor-dosare`
    }, [notification?.source])

    if(_.isNil(notification)) {
        return <React.Fragment />
    }

    return (
        <Slideover open={open && !_.isEmpty(notification)} title={notification.subject}>
            <div className="notification-details-container">
                <div className="notification-details-texts-container">
                    <p className="notification-details-changes-text">Modificări</p>
                    <p className="notification-details-message">{subtitle}</p>
                    {notification.changes?.map(({event, entity}: any, index: number) => (
                        <div key={index}>
                            {getMessageFromEvent(event, entity)}
                        </div>
                    ))}
                </div>
                <Link to={sourceLink}>
                    <Button title='Vezi sursa' onClick={closeSlideover} fullWidth className='button-cta' />
                </Link>
            </div>
        </Slideover>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.slideovers.type === slideoverTypes.NOTIFICATION_DETAILS,
    notification: state.notifications.currentNotification
})

const mapDispatchToProps = (dispatch: any) => ({
    closeSlideover: () => dispatch(closeSlideover())
})

export default connect(mapStateToProps, mapDispatchToProps)(MDNotificationDetails)