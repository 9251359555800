import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {Form, Formik} from 'formik'

import {
    addMinutes,
    differenceInHours,
    differenceInMinutes,
    getHours,
    getMinutes,
    isAfter,
    isSameDay,
    parseISO,
    setSeconds
} from 'date-fns'
import _ from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes} from '../../redux/modals'
import {saveLogAndClearLocaLTaskIDs} from '../../redux/taskTimeLogs'

import {taskTimeLogsSchema} from '../../assets/validations'
import {setTime, toApiDateFormat, toApiTimeFormat} from '../../utils'
import {debounceWait} from '../../utils/constants'
import {useDebouncedEffect} from '../../utils/hooks'
import {timeErrors} from '../../utils/types'

import {Button} from '../Button'
import {DatePicker} from '../DatePicker'
import {ErrorsList} from '../ErrorComponents'
import {Modal} from '../Modal'
import {RequiredFieldsText} from '../RequiredFieldsText'
import {Select} from '../Select'
import {TaskCreatable} from '../TaskCreatable'
import {Toggle} from '../Toggle'

import './CheckTaskTimeLogModal.scss'

export const CheckTaskTimeLogModal = ({
    open,
    currentTimeLog,
    nonFieldErrors,
    fieldErrors,
    isLoading,
    projects,
    isLoadingProjects,
    searchProjects,
    tasks,
    retrieveTask,
    updateTask,
    retrieveProject,
    selectedEntityID,
    saveLogAndClearLocaLTaskIDs
}) => {
    const [projectsQuery, setProjectsQuery] = useState('')
    const [selectedProject, setSelectedProject] = useState(null)
    const [timeError, setTimeError] = useState(null)
    const [markSelectedTaskAsFinished, setMarkSelectedTaskAsFinished] = useState(false)

    useEffect(() => {
        if(open && !_.isNil(currentTimeLog.task_id)) {
            retrieveTask(currentTimeLog.task_id)
        }
    }, [currentTimeLog.task_id, open, retrieveTask])

    useEffect(() => {
        if(open && !_.isNil(currentTimeLog.project_id)) {
            retrieveProject(currentTimeLog.project_id)
        }
    }, [currentTimeLog.project_id, open, retrieveProject])

    const handleFetchProjects = useCallback(() => {
        searchProjects(projectsQuery)
    }, [projectsQuery, searchProjects])

    const handleChangeProjectsSearchField = useCallback((value) => setProjectsQuery(value) , [])

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    const initialTime = useMemo(() => {
        if(!_.isEmpty(currentTimeLog) && !_.isNull(currentTimeLog.stop)) {
            const start = parseISO(`${currentTimeLog.date}T${currentTimeLog.start}`)
            const stop = parseISO(`${currentTimeLog.date}T${currentTimeLog.stop}`)

            const timeHours = differenceInHours(new Date(stop), new Date(start) )
            const timeMin = differenceInMinutes(new Date(stop), new Date(start))

            return setTime(new Date(start), timeHours, timeMin, 0)
        }

        return null
    }, [currentTimeLog])

    const initialStart = useMemo(() => {

        if(!_.isNil(currentTimeLog?.date) && !_.isNil(currentTimeLog?.start)) {
            return new Date(parseISO(`${currentTimeLog.date}T${currentTimeLog.start}`))
        }

        return undefined
    }, [currentTimeLog.date, currentTimeLog.start])

    const initialStop = useMemo(() => {
        if(!_.isNil(currentTimeLog?.date) && !_.isNil(currentTimeLog?.stop)) {
            return new Date(parseISO(`${currentTimeLog.date}T${currentTimeLog.stop}`))
        }

        return undefined
    }, [currentTimeLog.date, currentTimeLog.stop])

    const onChangeInterval = useCallback((start, stop, setFieldValue) => {
        const diffInMin = differenceInMinutes(stop, start)

        setTimeError(null)

        if(_.isNull(start) || _.isNull(stop)) {
            setTimeError(timeErrors.missing_value)
        } else if(isAfter(start, stop)) {
            setFieldValue('time', null)
            setFieldValue('duration_billable', null)
            setTimeError(timeErrors.stop_before_start)
        } else if(diffInMin <= 1) {
            setFieldValue('stop', addMinutes(stop, 1))
            setFieldValue('time', setTime(new Date(), 0, 1, 0))
            setFieldValue('duration_billable', setTime(new Date(), 0, 1, 0))
        } else {
            const hours = parseInt(diffInMin / 60)
            const mins = parseInt(diffInMin % 60)

            setFieldValue('time', setTime(new Date(), 0, mins, hours))
            setFieldValue('duration_billable', setTime(new Date(), 0, mins, hours))
        }
    }, [])

    const onChangeTime = useCallback((time, stop, setFieldValue) => {
        // let hours = getHours(stop) - getHours(time) <= 24 ? getHours(stop) - getHours(time) : 24
        let hours = getHours(stop) - getHours(time)
        // const minutes = getMinutes(stop) - getMinutes(time) > 1 ? getMinutes(stop) - getMinutes(time) : 1
        let minutes = getMinutes(stop) - getMinutes(time)

        if(minutes < 0 && hours > 0) {
            minutes = 60 + minutes
            hours = hours - 1
        }
        else if(minutes < 0 && hours <= 0) {
            minutes = getMinutes(stop)
            hours = 0
            // TODO: Send error for the case beyond midnight.
        }
        else if (hours <= 0) {
            hours = 24 - hours
            // TODO: Send error for the case beyond midnight.
        }

        const auxStart = setTime(stop, hours, minutes, 0)

        setTimeError(null)

        if(!isSameDay(auxStart, stop)) {
            setTimeError(timeErrors.interval_too_long)
            setFieldValue('start', setTime(new Date(stop), 0, 0, 0))
        } else {
            setFieldValue('start', auxStart)
        }
    }, [])

    const initialProject = useMemo(() => {
        if (!_.isNil(currentTimeLog?.project?.id)) {
            const project = _.find(
                projects,
                (project) =>
                    parseInt(project.id) === parseInt(currentTimeLog.project?.id)
            )

            if (
                !_.isEmpty(project) &&
                (_.isNull(selectedProject) ||
                    (!_.isNull(selectedProject) &&
                        parseInt(selectedProject?.id) !== parseInt(project?.id)))
            ) {
                setSelectedProject(project)
            }

            return project || undefined
        }
    }, [currentTimeLog?.project?.id, projects, selectedProject])

    const initialTask =useMemo(() => {
        if(!_.isNil(currentTimeLog?.task_id)) {
            return _.find(tasks, (task) => task.id === currentTimeLog.task_id) || null
        }

        return undefined
    }, [currentTimeLog?.task_id, tasks])

    const initialDate = useMemo(() => {
        if(!_.isNil(currentTimeLog.date) && !_.isNil(currentTimeLog.start)) {
            return new Date(parseISO(`${currentTimeLog.date}T${currentTimeLog.start}`))
        }

        return undefined
    }, [currentTimeLog.date, currentTimeLog.start])

    const handleMarkTaskAsFinished = useCallback((task) => {
        if(_.isNull(task.completion)) {
            updateTask(
                {
                    completion: `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`
                },
                task.id
            )
        }
    }, [updateTask])

    return (
        <Modal open={open && !_.isEmpty(currentTimeLog)} title='Salvează pontaj'>
            {!_.isEmpty(currentTimeLog) && (
                <>
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            projectId: initialProject,
                            taskId: initialTask,
                            date: initialDate,
                            start: initialStart,
                            stop: initialStop,
                            time: initialTime,
                            duration_billable: initialTime,
                            description: currentTimeLog.description || ''
                        }}
                        validationSchema={taskTimeLogsSchema}
                        onSubmit={(values) => {
                            const taskTimeLogData = {
                                entity_id: selectedEntityID,
                                project_id: !_.isNull(values.projectId) ? values.projectId.id : null,
                                date: toApiDateFormat(values.date),
                                start: toApiTimeFormat(setSeconds(values.start, 0)),
                                stop: toApiTimeFormat(setSeconds(values.stop, 0)),
                                task_id: !_.isNull(values.taskId) ? values.taskId.id : null,
                                duration: !_.isNull(values.time) ?
                                    `${values.time.getHours()}:${values.time.getMinutes()}:00`
                                    : null,
                                description: values.description
                            }

                            saveLogAndClearLocaLTaskIDs(taskTimeLogData, currentTimeLog.id)
                            if(markSelectedTaskAsFinished) {
                                handleMarkTaskAsFinished(values.taskId)
                            }
                        }}
                    >
                        {({handleBlur, setFieldValue, handleSubmit, values, errors, touched, isValid}) => (
                            <Form className='edit-task-time-log-form-container'>
                                <Select
                                    label='Proiect'
                                    placeholder='Alege un proiect'
                                    value={values.projectId}
                                    options={projects}
                                    onChange={(option) => {
                                        setFieldValue('projectId', option)
                                        setFieldValue('taskId', null)
                                        setSelectedProject(option)
                                        setMarkSelectedTaskAsFinished(false)
                                    }}
                                    onInputChange={handleChangeProjectsSearchField}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    onBlur={handleBlur('projectId')}
                                    name='projectId'
                                    errors={fieldErrors}
                                    loading={isLoadingProjects}
                                    isClearable
                                />
                                <TaskCreatable
                                    value={values.taskId}
                                    onBlur={handleBlur('taskId')}
                                    onChange={(option) => {
                                        setFieldValue('taskId', option)
                                        setMarkSelectedTaskAsFinished(false)
                                    }}
                                    disabled={_.isNull(selectedProject)}
                                    name='taskId'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.taskId}
                                    projectId={values.projectId?.id || null}
                                />
                                <div className="edit-task-time-log-form-row asym-split">
                                    <DatePicker
                                        label='Data*'
                                        value={values.date}
                                        onChange={(value) => setFieldValue('date', new Date(value))}
                                        onBlur={handleBlur('date')}
                                        name='date'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.date}
                                        fullWidth
                                    />
                                    <div className={`${!_.isNull(timeError) ? 'has-error' : ''}`}>
                                        <DatePicker
                                            label='Ore lucrate'
                                            value={values.time}
                                            onChange={(date) => {
                                                setFieldValue('time', date)
                                                setFieldValue('duration_billable', date)
                                                onChangeTime(date, values.stop, setFieldValue)
                                            }}
                                            timeFormat="HH:mm"
                                            timeSelect
                                            fullWidth
                                        />
                                    </div>
                                    <div className={`${!_.isNull(timeError) ? 'has-error' : ''}`}>
                                        <DatePicker
                                            label='Ore facturabile'
                                            value={values.duration_billable}
                                            timeFormat="HH:mm"
                                            disabled
                                            timeSelect
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className={`interval-picker-container ${!_.isNull(timeError) ? 'has-error' : ''}`}>
                                    <p className="interval-picker-label">
                                        Interval
                                    </p>
                                    <div className='interval-datepickers-container'>
                                        <DatePicker
                                            value={values.start}
                                            onChange={(date) => {
                                                setFieldValue('start', date)
                                                onChangeInterval(date, values.stop, setFieldValue)
                                            }}
                                            timeFormat="HH:mm"
                                            timeSelect
                                            fullWidth
                                        />
                                        <p>-</p>
                                        <DatePicker
                                            value={values.stop}
                                            onChange={(date) => {
                                                setFieldValue('stop', date)
                                                onChangeInterval(values.start, date, setFieldValue)
                                            }}
                                            timeFormat="HH:mm"
                                            timeSelect
                                            fullWidth
                                        />
                                    </div>
                                    {!_.isNull(timeError) &&
                                        <p className='time-error-message'>{timeError.message}</p>
                                    }
                                </div>
                                <Toggle
                                    label='Marchează sarcina selectată ca finalizată'
                                    checked={markSelectedTaskAsFinished}
                                    onChange={setMarkSelectedTaskAsFinished}
                                    disabled={_.isNil(values.taskId)}
                                />
                                <div className="textarea-container">
                                    <label className="note-textarea-label">Notă activitate</label>
                                    <textarea
                                        value={values.description}
                                        className="note-textarea"
                                        placeholder="Adaugă o notă pentru activitate"
                                        onChange={(e) => setFieldValue('description', e.target.value)}
                                        name='description'
                                        rows={3}
                                    />
                                </div>
                                <RequiredFieldsText />
                                <Button
                                    title='Salvează'
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={isLoading}
                                    color='secondary'
                                    type='submit'
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ( {
    open: state.modals.type === modalTypes.CHECK_TASK_TIME_LOG,
    currentTimeLog: state.taskTimeLogs.currentTaskTimeLog,
    isLoading: state.taskTimeLogs.isLoading,
    nonFieldErrors: state.taskTimeLogs.nonFieldErrors,
    fieldErrors: state.taskTimeLogs.fieldErrors,
    projects: _.values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    tasks: _.values(state.tasks.searchData),
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    saveLogAndClearLocaLTaskIDs: (data, taskTimeLogID) => dispatch(saveLogAndClearLocaLTaskIDs(data, taskTimeLogID)),
    searchProjects: (query) => dispatch(RESOURCES_V1.projects.search(query, {active: true})),
    retrieveProject: (projectID) => dispatch(RESOURCES_V1.projects.retrieve(projectID)),
    retrieveTask: (taskID) => dispatch(RESOURCES.tasks.retrieve(taskID)),
    updateTask: (taskData, taskID) => dispatch(RESOURCES.tasks.update(taskData, taskID))
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckTaskTimeLogModal)