import {MagnifyingGlassIcon} from '@heroicons/react/24/outline'
import React from 'react'
import {useTable} from 'react-table'

import {head, isEmpty, isNull, omitBy, toString, values} from 'lodash'

import {connect} from 'react-redux'
import {openSlideover, slideoverTypes} from '../../redux/slideovers'

import {Button} from '../Button'
import {Input} from '../Input'
import {Loader} from '../Loader'
import {Slideover} from '../Slideover'

import {FilterTag} from './Filters'
import {Pagination} from './Pagination'

import './Datatable.scss'

function Datatable({
    title,
    data,
    columns,
    loading,
    headerButton,
    customHeader,
    filterable,
    openFiltersSlideover,
    filtersOpen,
    filtersForm,
    filters,
    handleRemoveFilter,
    searchable,
    searchValue,
    searchPlaceholder,
    onSearch,
    searchContainerButton,
    emptyText,
    totalPages,
    onChangePage,
    currentPage,
    nextPage,
    previousPage,
    hideTableHead
}) {
    const tableInstance = useTable({data, columns})
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = tableInstance

    const tableWidth = headerGroups.length === 1 ?
        head(headerGroups).headers.length :
        headerGroups.length

    const getAppliedFilters = () => omitBy(filters, (filter) => isEmpty(toString(filter.displayValue)))

    const appliedFilters = getAppliedFilters()

    return (
        <div className='avo-datatable-container'>
            <div className="avo-datatable-container-inner">
                <div className="avo-datatable-container-shadow">
                    <table className="avo-datatable" {...getTableProps()}>
                        <thead className="avo-datatable-head">
                            {!hideTableHead && (
                                <>
                                    <tr>
                                        <td colSpan={tableWidth} className="avo-datatable-head-td">
                                            <div className="avo-datatable-header-container">
                                                <div className="loader-container">
                                                    {title && <p className="table-title">{title}</p>}
                                                    {loading && <Loader />}
                                                </div>
                                                {headerButton && headerButton()}
                                            </div>
                                            {customHeader && customHeader()}
                                        </td>
                                    </tr>
                                    {filterable || searchable || searchContainerButton ? (
                                        <tr>
                                            <td colSpan={tableWidth}>
                                                <div className="avo-search-filters-container">
                                                    {filterable && (
                                                        <div className="avo-filters-container">
                                                            <Button title='Filtre' onClick={openFiltersSlideover} />
                                                            <Slideover title='Filtre' open={filtersOpen}>
                                                                {filtersForm && filtersForm()}
                                                            </Slideover>
                                                        </div>
                                                    )}
                                                    {searchable && (
                                                        <div className="avo-search-container">
                                                            <Input
                                                                value={searchValue}
                                                                onChange={(event) => onSearch(event)}
                                                                placeholder={searchPlaceholder}
                                                                renderIcon={() =>
                                                                    <MagnifyingGlassIcon aria-hidden="true" />
                                                                }
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                                    {searchContainerButton && (
                                                        <div className="avo-search-container-button-container">
                                                            {searchContainerButton()}
                                                        </div>
                                                    )}
                                                </div>
                                                {!isEmpty(appliedFilters) ? (
                                                    <div className="filters-container">
                                                        <p className='filters-text'>Filtre aplicate: </p>
                                                        <div className="filters-list">
                                                            {values(appliedFilters).map((filter) => (
                                                                <FilterTag
                                                                    filter={filter}
                                                                    onRemove={() => handleRemoveFilter(filter)}
                                                                    key={filter.key}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ) : null}
                                </>
                            )}
                            {/* // Apply the header row props */}
                            <tr {...head(headerGroups).getHeaderGroupProps()}>
                                {// Loop over the headers
                                    head(headerGroups).headers.map(column => (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th
                                            {...column.getHeaderProps(
                                                // column.getSortByToggleProps()
                                            )}
                                            className='column-title'
                                        >
                                            {/* Render the header */}
                                            {column.render('Header')}

                                            {/* Add a sort direction indicator */}
                                            {/* <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <ArrowLongDownIcon className='h-3 float-right'/>
                                                            : <ArrowLongUpIcon className='h-3 float-right'/>
                                                        : ''}
                                                </span> */}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody className="avo-datatable-body" {...getTableBodyProps()}>
                            {!isEmpty(rows) ? (
                                rows.map((row) => {
                                    prepareRow(row)
                                    return (
                                        <tr className='table-row' {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps([{style: cell.column.style}])}
                                                        className='table-cell'
                                                    >
                                                        {/* Render the cell contents */}
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={tableWidth} className='table-cell'>
                                        {!isEmpty(emptyText) ? emptyText : 'Fără rezultate'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        {totalPages > 1 && (
                            <tfoot>
                                <tr>
                                    <td colSpan={tableWidth}>
                                        <div className="avo-datatable-footer">
                                            <Pagination
                                                total={totalPages}
                                                canNextPage={!isNull(nextPage)}
                                                onNext={() => onChangePage(nextPage)}
                                                canPreviousPage={!isNull(previousPage)}
                                                onPrevious={() => onChangePage(previousPage)}
                                                onChangePage={(page) => onChangePage(page)}
                                                currentPage={currentPage}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        )}
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    filtersOpen: state.slideovers.type === slideoverTypes.DATATABLE_FILTERS
})

const mapDispatchToProps = dispatch => ({
    openFiltersSlideover: () => dispatch(openSlideover(slideoverTypes.DATATABLE_FILTERS))
})

export default connect(mapStateToProps, mapDispatchToProps)(Datatable)
