import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {keepPreviousData} from '@tanstack/react-query'
import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {Notification, NotificationType} from 'types/api'

// @ts-ignore
import {setCurrent as setCurrentNotification} from 'avoapp-react-common/dist/redux/notifications'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
// @ts-ignore
import {openSlideover, slideoverTypes} from '../../redux/slideovers'

import {useQueryResourceList} from '../../queries/rest'

// @ts-ignore
import {Button} from '../../components/Button'
// @ts-ignore
import {Datatable} from '../../components/Datatable'

import {NewNotificationDot, NotificationDetailsSlideover} from './partials'

import './Notifications.scss'

type NotificationsProps = {
    appFilter: 'md' | 'bpi' | undefined,
    setCurrentNotification: (notification: Notification) => void,
    openSlideover: (slideoverType: string) => void
    selectedEntityID: number
}

export const Notifications = ({
    appFilter = undefined, setCurrentNotification, openSlideover, selectedEntityID
}: NotificationsProps) => {

    const [notifications, setNotifications] = useState<Notification[] | undefined>(undefined)
    const [page, setPage] = useState<number>(1)

    const filters = useMemo(() => ({
        app: appFilter,
        page_size: 15,
        entity_id: selectedEntityID,
        page
    }), [appFilter, page, selectedEntityID])

    const {data, isFetching} = useQueryResourceList(
        RESOURCES.notifications,
        filters,
        {
            placeholderData: keepPreviousData
        }
    )

    const notificationsResult = useMemo(() => {
        // To make typescript happy
        return data as any
    }, [data])

    const handleChangePage = useCallback((page) => setPage(page), [])

    const setSeen = useCallback( (notificationId) => {
        if(!_.isNil(notifications)) {
            const currentNotificationIdx = _.findIndex(notifications, ['id', notificationId])
            const currentNotification = {...notifications[currentNotificationIdx]}
            currentNotification.seen = true

            const clonedState = [...notifications]
            clonedState[currentNotificationIdx] = currentNotification

            setNotifications(clonedState)
        }
    },[notifications])

    const onClickView = useCallback((rowObject) => {
        setCurrentNotification(rowObject)
        openSlideover(slideoverTypes.NOTIFICATION_DETAILS)
        setSeen(rowObject.id)
    }, [openSlideover, setCurrentNotification, setSeen])

    useEffect(() => {
        if(!_.isNil(notificationsResult?.data?.results) && !isFetching) {
            setNotifications(notificationsResult.data.results)
        } else if(!isFetching) {
            setNotifications([])
        }
    }, [isFetching, notificationsResult])

    const getReadableNotificationType = useCallback((value: NotificationType): string => {
        switch (value) {
            case 'litigation':
                return 'Abonare'
            case 'bpi':
                return 'BPI'
            case 'party':
                return 'Parte'
            case 'upcoming_hearing':
                return 'Ședință'

            default:
                return '-'
        }

    }, [])

    const columns = useMemo(() => {
        return [
            {
                Header: 'Subiect',
                accessor: 'subject',
                Cell: ({value, row}: any) => (
                    <div className='notification-subject-datatabel-cell-container'>
                        <p
                            className={
                                `notification-subject-datatable-cell ${!row.original.seen ? 'new-notification' : ''}`
                            }
                        >
                            {value}
                        </p>
                        {!row.original.seen && <NewNotificationDot />}
                    </div>
                )
            },
            {
                Header: 'Client/Proiect',
                accessor: 'source',
                Cell: ({value}) => (
                    <p>{value.project?.name ? value.project.name : value.client?.name ? value.client.name : '-'}</p>
                )
            },
            {
                Header: 'Tip',
                accessor: 'type',
                Cell: ({value}) => getReadableNotificationType(value)
            },
            {
                Header: 'Data',
                accessor: 'sent_moment',
                Cell: ({value}) => lightFormat(new Date(value), 'dd/MM/yyyy HH:mm')
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row}) => (
                    <Button
                        variant='text'
                        title='Vizualizare'
                        onClick={() => onClickView(row.original)}
                        size='small'
                    />
                )
            }
        ]

    }, [getReadableNotificationType, onClickView])

    if(_.isNil(notificationsResult) || _.isNil(notifications)) {
        return <React.Fragment />
    }

    return (
        <>
            <Datatable
                title='Listă notificări'
                data={notifications}
                columns={columns}
                loading={isFetching}
                previousPage={notificationsResult.data?.pages?.previous}
                currentPage={notificationsResult.data?.page}
                nextPage={notificationsResult.data?.pages?.next}
                totalPages={notificationsResult.data?.number_of_pages}
                onChangePage={(page: number) => handleChangePage(page)}
            />
            <NotificationDetailsSlideover />
        </>
    )
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentNotification: (notification: any) => dispatch(setCurrentNotification(notification)),
    openSlideover: (slideoverType: string) => dispatch(openSlideover(slideoverType))
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)