import React, {useCallback, useMemo} from 'react'

import {TrashIcon} from '@heroicons/react/24/outline'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {setCurrent as setCurrentPartySubscription} from 'avoapp-react-common/dist/redux/partySubscriptions'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {setCurrent as setCurrentSubscription} from 'avoapp-react-common/dist/redux/subscriptions'
import {connect} from 'react-redux'
// @ts-ignore
import {modalTypes, openModal} from '../../../redux/modals'

import {Cell} from 'types/local'
import {useQueryResourceList} from '../../../queries/rest'
// @ts-ignore
import {useDebouncedState} from '../../../utils/hooks'
// @ts-ignore
import {sourceTypes} from '../../../utils/types'

// @ts-ignore
import {Button} from '../../../components/Button'
// @ts-ignore
import {Datatable} from '../../../components/Datatable'
// @ts-ignore
import {DatatableRowButtons} from '../../../components/DatatableRowButtons'
// @ts-ignore
import {DeletePartySubscriptionModal} from '../../../components/DeletePartySubscriptionModal'
// @ts-ignore
import {DeleteSubscriptionModal} from '../../../components/DeleteSubscriptionModal'
import {Link} from 'react-router-dom'

interface MonitorDosareDatatableProps {
    openModal: (modalType: string) => void
    openedModal: string
    selectedEntityID: string
    setCurrentSubscription: (subscription: any) => void
    setCurrentPartySubscription:(partySubscription: any) => void
}

export const MonitorDosareDatatable = ({
    openModal,
    openedModal,
    selectedEntityID,
    setCurrentSubscription,
    setCurrentPartySubscription
}: MonitorDosareDatatableProps) => {
    const [subscriptionsSearchFilter, setSubscriptionsSearchFilter] = useDebouncedState('', 300)
    const [subscriptionsPage, setSubscriptionsPage] = React.useState<number>(1)

    const subscriptionsFilters = useMemo(() => ({
        search: subscriptionsSearchFilter,
        entity_id: selectedEntityID,
        page_size: 15,
        page: subscriptionsPage
    }), [subscriptionsPage, subscriptionsSearchFilter, selectedEntityID])

    const {data: subRes, isFetching: isFetchingSubscriptions} = useQueryResourceList(
        RESOURCES.subscriptions,
        subscriptionsFilters,
        undefined,
        //we want to refetch the data when the delete modal closes
        _.isNil(openedModal).toString()
    )

    const subscriptionsResult = useMemo(() => {
        // To make typescript happy
        return subRes as any
    }, [subRes])

    const handleChangeSubscriptionsSearchField = (value: string) => setSubscriptionsSearchFilter(value)

    const handleChangeSubscriptionsPage = useCallback((newPage: number) => {setSubscriptionsPage(newPage)}, [])

    const subscriptionsColumns = useMemo(() => {
        return [
            {
                Header: 'Număr dosar',
                accessor: 'litigation_number'
            },
            {
                Header: 'Sursa',
                accessor: 'source',
                Cell: ({value: source}: Cell) => sourceTypes[_.toUpper(source)] || '-'
            },
            {
                Header: 'Ultimul update',
                accessor: 'latest_update',
                Cell: ({value: latestUpdate}: Cell) => !_.isNil(latestUpdate) ?
                    lightFormat(new Date(latestUpdate), 'dd/MM/yyyy HH:mm') :
                    '-'
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({value: subscriptionID, row: {original: subscription}}: Cell) => (
                    <div className='flex gap-2 items-center'>
                        <div className='datatable-row-buttons-container'>
                            <Link to={`/external-data/monitor-dosare/${subscriptionID}`}>
                                <Button title='Vizualizare' size='small' />
                            </Link>
                            <Button
                                icon={() => <TrashIcon />}
                                onClick={() => {
                                    setCurrentSubscription(subscription)
                                    openModal(modalTypes.DELETE_SUBSCRIPTION)
                                }}
                                size='small'
                                color='red'
                            />
                        </div>
                    </div>
                )
            }
        ]
    }, [openModal, setCurrentSubscription])

    const [partySubscriptionsSearchFilter, setPartySubscriptionsSearchFilter] = useDebouncedState('', 300)
    const [partySubscriptionsPage, setPartySubscriptionsPage] = React.useState(1)

    const partySubscriptionsFilters = useMemo(() => ({
        search: partySubscriptionsSearchFilter,
        entity_id: selectedEntityID,
        page_size: 15,
        page: partySubscriptionsPage
    }), [partySubscriptionsPage, partySubscriptionsSearchFilter, selectedEntityID])

    const {data: partySubRes, isFetching: isFetchingPartySubscriptions} = useQueryResourceList(
        RESOURCES.partySubscriptions,
        partySubscriptionsFilters,
        undefined,
        //we want to refetch the data when the delete modal closes
        _.isNil(openedModal).toString()
    )

    const partySubscriptionsResult = useMemo(() => {
        // To make typescript happy
        return partySubRes as any
    }, [partySubRes])

    const handleChangePartySubscriptionsSearchField = (value: string) => setPartySubscriptionsSearchFilter(value)

    const handleChangePartySubscriptionsPage = useCallback((newPage: number) => {
        setPartySubscriptionsPage(newPage)
    }, [])

    const partySubscriptionsColumns = useMemo(() => {
        return [
            {
                Header: 'Cuvânt cheie',
                accessor: 'party_name',
                Cell: ({value}: Cell) => `"${value}"`
            },
            {
                Header: 'Sursa',
                accessor: 'source',
                Cell: ({value: source}: Cell) => sourceTypes[_.toUpper(source)] || '-'
            },
            {
                Header: 'Instituția',
                accessor: 'institution'
            },
            {
                Header: 'Ultima sincronizare',
                accessor: 'sync_moment',
                Cell: ({value}: Cell) => !_.isNil(value) ? lightFormat(new Date(value), 'dd/MM/yyyy HH:mm') : '-'
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: partySubscription}}: Cell) => (
                    <DatatableRowButtons
                        onDeleteButtonClick={() => {
                            setCurrentPartySubscription(partySubscription)
                            openModal(modalTypes.DELETE_PARTY_SUBSCRIPTION)
                        }}
                    />
                )
            }
        ]
    }, [openModal, setCurrentPartySubscription])

    if(_.isNil(subscriptionsResult?.data) || _.isNil(partySubscriptionsResult)) {
        return <React.Fragment />
    }

    return (
        <div className='page-external-data-show'>
            <Datatable
                title='Listă dosare monitorizate'
                data={subscriptionsResult.data.results}
                columns={subscriptionsColumns}
                loading={isFetchingSubscriptions}
                previousPage={subscriptionsResult.data?.pages?.previous}
                currentPage={subscriptionsResult.data?.page}
                nextPage={subscriptionsResult.data?.pages?.next}
                totalPages={subscriptionsResult.data?.number_of_pages}
                onChangePage={(page: number) => handleChangeSubscriptionsPage(page)}
                searchable
                searchValue={subscriptionsSearchFilter}
                searchPlaceholder='Caută surse de date'
                onSearch={(event: any) => handleChangeSubscriptionsSearchField(event.target.value)}
            />
            <DeleteSubscriptionModal />
            <Datatable
                title='Listă părți monitorizate'
                data={partySubscriptionsResult.data.results}
                columns={partySubscriptionsColumns}
                loading={isFetchingPartySubscriptions}
                previousPage={partySubscriptionsResult.data?.pages?.previous}
                currentPage={partySubscriptionsResult.data?.page}
                nextPage={partySubscriptionsResult.data?.pages?.next}
                totalPages={partySubscriptionsResult.data?.number_of_pages}
                onChangePage={(page: number) => handleChangePartySubscriptionsPage(page)}
                searchable
                searchValue={partySubscriptionsSearchFilter}
                searchPlaceholder='Caută monitorizări juridice'
                onSearch={(event: any) => handleChangePartySubscriptionsSearchField(event.target.value)}
            />
            <DeletePartySubscriptionModal />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    openedModal: state.modals.type,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    openModal: (modalType: string) => dispatch(openModal(modalType)),
    setCurrentSubscription: (subscription: any) => dispatch(setCurrentSubscription(subscription)),
    setCurrentPartySubscription: (partySubscription: any) => dispatch(setCurrentPartySubscription(partySubscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(MonitorDosareDatatable)