import React, {useCallback, useMemo, useState} from 'react'

import {Form, Formik} from 'formik'

import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters} from '../../../../../../redux/filters/filters'
import {closeSlideover} from '../../../../../../redux/slideovers'

import {debounceWait} from '../../../../../../utils/constants'
import {useDebouncedEffect} from '../../../../../../utils/hooks'

import {Button} from '../../../../../../components/Button'
import {ErrorsList} from '../../../../../../components/ErrorComponents'
import {Select} from '../../../../../../components/Select'

import '../../../../../../assets/scss/SlideoverForms.scss'
import {DatePicker} from '../../../../../../components/DatePicker'
import {toApiDateFormat} from '../../../../../../utils'
import {lightFormat} from 'date-fns'

const FilterExpensesForm = ({
    clientID,
    projectID,
    filters,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    selectedEntityID,
    addFilters,
    filterExpenses,
    closeSlideover
}) => {
    const [clientsQuery, setClientsQuery] = useState('')
    const [clients, setClients] = useState(undefined)
    const [isLoadingClients, setIsLoadingClients] = useState(false)

    const [projectsQuery, setProjectsQuery] = useState('')
    const [projects, setProjects] = useState(undefined)
    const [isLoadingProjects, setIsLoadingProjects] = useState(false)

    const handleFilter = useCallback((filters) => {
        addFilters(filters)
        filterExpenses()
        closeSlideover()
    }, [addFilters, closeSlideover, filterExpenses])

    const onChangeClientsInput = useCallback((value) => setClientsQuery(value), [])

    const handleFetchClients = useCallback(async () => {
        setIsLoadingClients(true)

        const {data} = await performRequest(RESOURCES.clients.list({
            entity_id: selectedEntityID,
            search: clientsQuery,
            project_id: projectID
        }))
        setClients(data.results)

        setIsLoadingClients(false)
    }, [clientsQuery, projectID, selectedEntityID])

    useDebouncedEffect(handleFetchClients, [projectsQuery], debounceWait)

    const onChangeProjectsInput = useCallback((value) => setProjectsQuery(value), [])

    const handleFetchProjects = useCallback(async (search) => {
        setIsLoadingProjects(true)

        const {data} = await performRequest(RESOURCES_V2.projects.list({
            entity_id: selectedEntityID,
            search: search,
            client_id: clientID

        }))
        setProjects(data.results)

        setIsLoadingProjects(false)
    }, [clientID, selectedEntityID])

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    const initialClient = useMemo(() => {
        return _.find(clients, (client) => filters.client.value === client.id)
    }, [clients, filters.client.value])

    const initialProject = useMemo(() => {
        return _.find(projects, (project) => filters.project.value === project.id)
    }, [filters.project.value, projects])

    const handleChangeDate = useCallback((field, date, setFieldValue) => {
        if(!_.isNil(date)) {
            setFieldValue(field, new Date(date))
        } else {
            setFieldValue(field, date)
        }
    }, [])

    return (
        <div className="slideover-form-container">
            <>
                <ErrorsList errors={nonFieldErrors} />
                <Formik
                    initialValues={{
                        client: initialClient,
                        project: initialProject,
                        dateLTE: filters.dateLTE?.value ? new Date(filters.dateLTE.value) : null,
                        dateGTE: filters.dateGTE?.value ? new Date(filters.dateGTE.value) : null
                    }}
                    onSubmit={(values) => {
                        const localFormatDate = (date) => {
                            if(_.isNil(date) || date === '') {
                                return null
                            }
                            return lightFormat(new Date(date), 'dd/MM/yyyy')
                        }

                        const filtersData = {
                            client: {
                                value: values.client?.id || '',
                                displayValue: values.client?.name || ''
                            },
                            project: {
                                value: values.project?.id || '',
                                displayValue: values.project?.name || ''
                            },
                            dateLTE: {
                                value: toApiDateFormat(values.dateLTE),
                                displayValue: localFormatDate(values.dateLTE)
                            },
                            dateGTE: {
                                value: toApiDateFormat(values.dateGTE),
                                displayValue: localFormatDate(values.dateGTE)
                            }
                        }

                        handleFilter(filtersData)
                    }}
                >
                    {({setFieldValue, handleSubmit, values}) => (
                        <>
                            <Form className='slideover-form'>
                                {_.isNil(clientID) && (
                                <Select
                                    label='Client'
                                    value={values.client}
                                    options={clients}
                                    onChange={(e) => setFieldValue('client', e)}
                                    onInputChange={onChangeClientsInput}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    loading={isLoadingClients}
                                    name='clients'
                                    errors={fieldErrors}
                                    fullWidth
                                />
                                )}
                                {_.isNil(projectID) && (
                                <Select
                                    label='Project'
                                    value={values.project}
                                    options={projects}
                                    onChange={(e) => setFieldValue('project', e)}
                                    onInputChange={onChangeProjectsInput}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    loading={isLoadingProjects}
                                    name='projects'
                                    errors={fieldErrors}
                                    fullWidth
                                />
                                )}
                                <DatePicker
                                    label='Dată start'
                                    value={values.dateGTE}
                                    onChange={(date) => handleChangeDate('dateGTE', date, setFieldValue)}
                                    fullWidth
                                />
                                <DatePicker
                                    label='Dată stop'
                                    value={values.dateLTE}
                                    onChange={(date) => handleChangeDate('dateLTE', date, setFieldValue)}
                                    fullWidth
                                />
                            </Form>
                            <div className="buttons-container">
                                <Button
                                    title='Aplică'
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    type='submit'
                                    fullWidth
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </>
        </div>
    )
}

const mapStateToProps = (state) => ({
    filters: state.filters.expenses,
    isLoading: state.expenses.isLoading,
    fieldErrors: state.expenses.fieldErrors,
    nonFieldErrors: state.expenses.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.expenses.name, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterExpensesForm)
