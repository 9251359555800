import {useEffect} from 'react'

import _ from 'lodash'

// We need to separate this into a hook to be able to use the same logic in InvoiceEntriesSection
// and in EditInvoiceEntryModal
export const useExchangeRateAndTotalLogic = (
    isInvoiceCurrencyRON,
    isEntryCurrencyRON,
    invoiceExchangeRate,
    exchangeRates,
    values,
    setFieldValue
) => {
    // Get exchange rate
    useEffect(() => {
    // If the invoice currency is not RON, the entries can only be in the invoice currency
    // so we will use the invoice's exchange rate
        if(!isInvoiceCurrencyRON) {
            setFieldValue('exchangeRate', invoiceExchangeRate)
        } else {
        // If we have other currency then RON (but the invoice has RON currency)
        // we take the correct currency from the "exchangeRates" array
            if(!_.isNil(values.currency?.value) && !isEntryCurrencyRON) {
                setFieldValue('exchangeRate', _.get(exchangeRates, values.currency.value))
            } else {
            // If the currency is RON for both the entry and the invoice we reset the exchangeRate and priceRON
                setFieldValue('exchangeRate', '')
                setFieldValue('priceRON', '')
            }
        }
    }, [
        exchangeRates,
        invoiceExchangeRate,
        isEntryCurrencyRON,
        isInvoiceCurrencyRON,
        setFieldValue,
        values.currency?.value
    ])

    useEffect(() => {
        if(values.exchangeRate !== '') {
            setFieldValue('priceRON', _.round(values.exchangeRate * values.price), 2)
        } else {
            setFieldValue('priceRON', values.price)
        }
    }, [setFieldValue, values.exchangeRate, values.price])

    useEffect(() => {
        const total = _.round(values.price * values.quantity, 2)
        setFieldValue('total', total)

        if(!_.isNull(values.currency) && !isEntryCurrencyRON) {
            setFieldValue('totalRON', _.round(values.priceRON * values.quantity), 2)
        } else {
            setFieldValue('totalRON', total)
        }
    }, [isEntryCurrencyRON, setFieldValue, values.currency, values.price, values.priceRON, values.quantity])

    useEffect(() => {
        if(values.total === '' || values.totalRON === '') {
            setFieldValue('taxValue', '')
            setFieldValue('taxValueRON', '')
        } else {
            const taxValue = _.round((values.tax.value / 100) * values.total, 2)
            const taxValueRON = _.round((values.tax.value / 100) * values.totalRON, 2)

            setFieldValue('taxValue', taxValue)
            setFieldValue('taxValueRON', taxValueRON)
        }
    }, [setFieldValue, values.tax.value, values.total, values.totalRON])
}
