import React, {useCallback, useEffect, useMemo, useRef} from 'react'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {useInfiniteQuery} from '@tanstack/react-query'

import {Button} from '../../../components/Button'
import {Loader} from '../../../components/Loader'

import './BPINotificationsSection.scss'

export const BPINotificationsSection = ({projectID, openedModal}) => {
    const loaderRef = useRef(null)

    const filters = useMemo(() => ({
        project_id: projectID || null,
        app: 'bpi'
    }), [projectID])

    const fetchNotifications = useCallback(async ({pageParam = 1}) => {
        return performRequest(RESOURCES.notifications.list({...filters, page: pageParam}))
    }, [filters])

    const {data: notificationsResult, isFetching, fetchNextPage, hasNextPage} = useInfiniteQuery({
        queryKey:[
            RESOURCES.notifications,
            filters,
            //we want to refetch the data when the add or delete modal closes
            _.isNil(openedModal)
        ],
        queryFn: fetchNotifications,
        getNextPageParam: (response) => response?.data?.pages?.next
    })

    const handleFetchNextPage = useCallback((params) => {
        if(hasNextPage) {
            fetchNextPage()
        }
    }, [fetchNextPage, hasNextPage])

    const orderedNotificationsData = useMemo(() => {
        if(_.isNil(notificationsResult)) {
            return []
        }

        const allData = notificationsResult.pages.flatMap((page) => page.data.results)
        return _.orderBy(allData, 'sent_moment', 'desc')
    }, [notificationsResult])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0]
            if (target.isIntersecting) {
                handleFetchNextPage()
            }
        })

        if (loaderRef.current) {
            observer.observe(loaderRef.current)
        }

        return () => {
            if (loaderRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(loaderRef.current)
            }
        }
    }, [handleFetchNextPage])

    return (
        <div className="bpi-notifications-container">
            <div className='bpi-notifications-list-container'>
                <p className="section-title">Notificari BPI</p>
                {orderedNotificationsData && orderedNotificationsData.length >0 ? (
                    <ul className="space-y-6">
                        {orderedNotificationsData.map((notification) => (
                            <BPINotificationItem notification={notification} />
                        ))}
                        <div ref={loaderRef}>
                            {isFetching && (
                                <div className="center-loader">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </ul>
                ) : !isFetching && (
                    <p>Nu există notificări pentru abonările BPI din acest proiect</p>
                )}
            </div>
        </div>
    )
}

function BPINotificationItem({notification}) {
    return (
        <li className="notification-info-item-container" key={notification.unique_id}>
            <div className='notification-info-item-line-container'>
                <div className="notification-info-item-line" />
            </div>
            <div className="notification-info-item-dot-container">
                <div className="notification-info-item-dot" />
            </div>
            <div className="notification-details-container">
                <div className="notification-details-header">
                    <div className='text-sm'>
                        {!_.isNil(notification.sent_moment) && (
                            <p className="notification-time">
                                {lightFormat(new Date(notification.sent_moment),'dd/MM/yyyy')}
                                {' - '}
                                {lightFormat(new Date(notification.sent_moment),'HH:mm')}
                            </p>
                        )}
                    </div>
                    <p className='notification-phase'>
                        BPI
                    </p>
                </div>

                <div className="notification-details-section mb-6">
                    {!_.isNil(notification.message) && (
                        <p className="notification-detail-key  line-clamp-5">
                            <span className="font-bold">Mesaj:</span> {notification.message}
                        </p>
                    )}
                </div>

                <a href={notification.data.notification_url} target='_blank' rel="noreferrer" >
                    <Button
                        title='Vezi documentul'
                        size='small'
                    />
                </a>
            </div>
        </li>
    )
}

const mapStateToProps = (state) => ({
    openedModal: state.modals.type
})

export default connect(mapStateToProps)(BPINotificationsSection)
