import React from 'react'

import {ExpensePaymentsList, ExpensesList} from './partials'
import ExpenseBalance from './partials/ExpenseBalance'

const Expenses = (props) => {
    return (
        <>
            <ExpenseBalance {...props} />
            <ExpensesList {...props} />
            <ExpensePaymentsList {...props} />
        </>
    )
}

export default Expenses
