
import React, {useEffect} from 'react'

import _ from 'lodash'

import {logout} from 'avoapp-react-common/dist/redux/auth'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {pastTasksFilters, todayTasksFilters} from './constants'
import {ClockCard, TasksCard, TimeCards} from './partials'

import './Dashboard.scss'

export const Dashboard = ({user, retrieveUser, logout}) => {
    useEffect(() => {
        if(_.isEmpty(user)) {
            retrieveUser()
        }
    }, [retrieveUser, user])

    return (
        <div className="dashboard-container">
            <ClockCard name={user?.first_name} />
            <TimeCards />
            <TasksCard
                title='Sarcinile tale de astăzi'
                filters={todayTasksFilters}
            />
            <TasksCard
                title='Sarcinile tale nefinalizate, din trecut'
                filters={pastTasksFilters}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
    retrieveUser: () => dispatch(RESOURCES.users.retrieve('me')),
    logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)