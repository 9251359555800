import React, {useCallback} from 'react'

import _, {isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes, openModal} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import '../../assets/scss/DeleteModals.scss'
import {useDeleteSmartBillInvoice} from '../../mutations/rest'
import {externalSyncActions} from '../../pages/Invoices/constants'
import {toast} from 'react-toastify'

function DeleteInvoiceModal({open, selectedInvoice, isLoading, deleteInvoice, closeModal, openSyncInvoiceModal}) {
    const onSmartBillFinished = useCallback((data, action) => {
        const {errors, status} = data

        if (_.includes([403, 418], status)) {
            // SmartBill is not enabled for this entity. Missing API key or action not needed.
            return
        }

        if (status >= 400) {
            const errorMessage = _.get(
                errors, 'non_field_errors.0.message', 'A apărut o eroare la sincronizarea cu SmartBill'
            )
            openSyncInvoiceModal(selectedInvoice.id, action, errorMessage)
        } else {
            toast('Factura a fost sincronizată cu succes în SmartBill', {type: 'success'})
        }
    }, [selectedInvoice, openSyncInvoiceModal])

    const {mutateAsync: deleteSmartBillInvoice} = useDeleteSmartBillInvoice({
        onSuccess: (data, variables, context) => {onSmartBillFinished(data, externalSyncActions.DELETE)}
    })

    return (
        <Modal open={open && !isEmpty(selectedInvoice)} title='Șterge factură fiscală'>
            <div className='delete-modal'>
                <p className='message'>Ești sigur că vrei să ștergi această factură fiscală?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={async () => {
                            const {status: deleteHTTPStatus} = await deleteSmartBillInvoice(selectedInvoice)
                            if(deleteHTTPStatus === 400) {
                                return
                            }

                            deleteInvoice(selectedInvoice)
                        }}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_INVOICE,
    isLoading: state.invoices.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteInvoice: (selectedInvoice) => dispatch(RESOURCES.invoices.destroy(selectedInvoice)),
    openSyncInvoiceModal: (invoiceID, action, errorMessage) => dispatch(
        openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {invoiceID, action, errorMessage})
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoiceModal)
