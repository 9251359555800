import _ from 'lodash'

// @ts-ignore
import {ColorPreview} from '../../../../components/ColorPreview'
import React from 'react'
import {EntityProfileMinimal, Task} from '../../../../types/api'
import {renderInterval} from './partials/utils'

type Row = {
    original: Task
}

export const columns =[
    {
        Header: 'Interval',
        accessor: '_interval',
        Cell: ({row: {original: task}} : {row: Row}) => renderInterval(task),
        style: {whiteSpace: 'nowrap'}
    },
    {
        Header: 'Sarcină',
        accessor: 'title',
        Cell: ({row: {original: task}}: {row: Row}) => task.color ? (
            <div className="tasks-list-datatable-task-title-column">
                <ColorPreview color={task.color} />
                {task.title}
            </div>
        ) : task.title,
        style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Responsabili',
        accessor: 'owners',
        Cell: ({value: owners}: {value: Array<EntityProfileMinimal>}) => {
            return _.join(owners.map((owner) => owner.full_name), ', ')
        }
    },
    {
        Header: 'Proiect',
        accessor: 'project.name',
        style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Clienți',
        accessor: 'clients_names',
        Cell: ({value: clientsNames}: {value: Array<string>}) => _.join(clientsNames, ', ')
    },
    {
        Header: 'Ore Lucrate',
        accessor: 'duration_as_hours'
    }

]

export const isCompletedOptions = {
    FALSE: {
        label: 'Active',
        value: false
    },
    TRUE: {
        label: 'Finalizate',
        value: true
    }
}
