import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function DeleteContractModal({open , contract, isLoading, closeModal, deleteContract}) {
    return (
        <Modal open={open && !isEmpty(contract)} title='Șterge contract'>
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi contractul "<span>{contract.name}</span>"?
                </p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => deleteContract(contract)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open : state.modals.type === modalTypes.DELETE_CONTRACT,
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteContract: (contract) => dispatch(RESOURCES.contracts.destroy(contract))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContractModal)