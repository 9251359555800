import {takeEvery, put, take, select} from 'redux-saga/effects'

import {find, includes} from 'lodash'

import {Action} from 'avoapp-react-common/dist/redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal} from './modals'

const {companies: resource} = RESOURCES

const ACTIONS = {
    SET_COMPANY_DETAILS: new Action('SET_COMPANY_DETAILS')
}

function* handleGetCompanyDetails({payload: {vatCode, setFieldValue}}) {
    yield put(RESOURCES.companies.retrieve(vatCode))
    const retrieveCompaniesResponse = yield take(action =>
        action.type === resource.actions.retrieve.success ||
        action.type === resource.actions.retrieve.fail
    )

    if(retrieveCompaniesResponse.type === resource.actions.retrieve.success) {
        const companies = yield select((state) => state.companies.data)
        const states = yield select((state) => state.geo.states)

        const company = find(companies, (comp) => includes(comp.vat_number, vatCode))
        const county = find(states, ['name', company.county])

        setFieldValue('vatCode', company.vat_number)
        setFieldValue('address', company.address)
        setFieldValue('phone', company.phone)
        setFieldValue('businessRegistrationNumber', company.trade_register_number)
        setFieldValue('locality', company.locality)
        setFieldValue('county', county ? county : company.county)
        setFieldValue('country', {name: 'Romania', code: 'RO'})
    }

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(ACTIONS.SET_COMPANY_DETAILS.main, handleGetCompanyDetails)

}

export const setCompanyDetails = (vatCode, setFieldValue) => {
    return ({
        type: ACTIONS.SET_COMPANY_DETAILS.main,
        payload: {vatCode, setFieldValue}
    })
}
