import React, {useCallback, useEffect, useMemo, useState} from 'react'

import _ from 'lodash'
import {toast} from 'react-toastify'

import {connect} from 'react-redux'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {getCompaniesFromName} from 'avoapp-react-common/dist/redux/companies'
// @ts-ignore
import {closeModal, modalTypes} from '../../../../../../../redux/modals'

// @ts-ignore
import {bpiAddTabs} from '../../../constants'

import {Tabs} from 'components/Tabs'
// @ts-ignore
import {Modal} from '../../../../../../../components/Modal'
// @ts-ignore
import {ErrorComponent} from '../../../../../../../components/ErrorComponents/ErrorsList'

import CompaniesList from './CompaniesList'
import SearchDataSourcesForm from './SearchDataSourcesForm'

import './AddBPISubscriptionModal.scss'

interface AddBPISubscriptionModalProps {
    projectID: string
    open: boolean
    closeModal: () => void
}

export const AddBPISubscriptionModal = ({open, closeModal, projectID}: AddBPISubscriptionModalProps) => {
    const [selectedTab, setSelectedTab] = useState<string>(bpiAddTabs.WITH_VAT_CODE)
    const [companiesResult, setCompaniesResult] = useState<any>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isNameFormSelected = useMemo(() => {
        return selectedTab === bpiAddTabs.WITH_NAME
    }, [selectedTab])

    useEffect(() => {
        if(!open) {
            setCompaniesResult(null)
        }
    }, [open])

    const handleChangeTab = useCallback((tab) => setSelectedTab(tab), [])

    const onAddSubscription = useCallback(({vatNumber, companyName}) => {
        setIsLoading(true)
        performRequest(
            RESOURCES.bpiSubscriptions.create({
                project_id: projectID,
                vat_number: vatNumber,
                company_name: companyName
            })
        ).then((res: any) => {
            setIsLoading(false)
            if(!res.errors) {
                toast.success('Salvat')
                closeModal()
            } else {
                _.forEach(_.values(res.errors), (error) => {
                    if(Array.isArray(error)) {
                        _.forEach(error, (err) => {
                            toast.error(err.message)
                        })
                    } else {
                        toast.error(error.message)
                    }
                })
            }
        })
    }, [closeModal, projectID])

    const onSearchCompany = useCallback(({companyName}) => {
        setIsLoading(true)
        performRequest(getCompaniesFromName(companyName)).then((res: any) => {
            setIsLoading(false)
            setCompaniesResult(res)
        })
    }, [])

    const hasErrors = useMemo(() => {
        return !_.isNil(companiesResult) &&
        _.isEmpty(companiesResult.data) &&
        !_.isEmpty(companiesResult.errors?.non_field_errors)
    }, [companiesResult])

    return (
        <Modal open={open} title='Adaugă abondare Monitor BPI' maxWidth='80%' minWidth='1000px'>
            <div className="add-bpi-subscription-modal-content">
                <div className="search-data-sources-container">
                    <p className="section-title">Caută surse de date</p>
                    <Tabs
                        tabs={_.values(bpiAddTabs)}
                        selectedTab={selectedTab}
                        onChangeTab={handleChangeTab}
                    />
                    <SearchDataSourcesForm
                        selectedTab={selectedTab}
                        onAddSubscription={onAddSubscription}
                        onSearchCompany={onSearchCompany}
                        isLoading={isLoading}
                    />
                </div>

                {isNameFormSelected && (
                    <div className="results-list-container">
                        <p className="section-title">Alege o companie</p>
                        {hasErrors ? (
                            <ErrorComponent message={companiesResult.errors.non_field_errors.message} />
                        ) : (
                            <>
                                {_.isNil(companiesResult) && (
                                    <p className='companies-list-empty'>Aici vor aparea rezultatele in urma cautarii</p>
                                )}

                                {!_.isNil(companiesResult) && _.isEmpty(companiesResult?.data?.results) && (
                                    <p className='companies-list-empty'>Nu s-au gasit companii cu numele acesta</p>
                                )}

                                {!_.isNil(companiesResult) && !_.isEmpty(companiesResult?.data?.results) && (
                                    <CompaniesList
                                        companies={companiesResult?.data?.results}
                                        onAddSubscription={onAddSubscription}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_MONITOR_BPI_SUBSCRIPTION
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBPISubscriptionModal)
