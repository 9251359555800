
import React, {useCallback, useMemo} from 'react'
import {connect} from 'react-redux'

import _ from 'lodash'

import {useFormik} from 'formik'

import {invoicesSchema} from '../../../../assets/validations'
import {RON_CURRENCY_VALUE} from '../../../../utils/constants'

import {Button} from '../../../Button'
import {Input} from '../../../Input'
import {Modal} from '../../../Modal'
import {Select} from '../../../Select'

import {useExchangeRateAndTotalLogic} from '../../hooks/useExchangeRateAndTotalLogic'

import './EditInvoiceEntryModal.scss'

export const EditInvoiceEntryModal = ({
    open,
    onClose,
    entry,
    entryIdx,
    autocompleteEntryNameOptions,
    invoiceContract,
    unitOptions,
    taxPercentageOptions,
    currencies,
    priceInputLabel,
    isInvoiceCurrencyRON,
    invoiceExchangeRate,
    exchangeRates,
    handleEditEntry,
    isUsingVat
}) => {
    const {handleChange, setFieldValue, handleBlur, handleSubmit, handleReset, values, errors, touched} = useFormik({
        initialValues: {
            name: entry?.name || '',
            unit: entry?.unit || null,
            quantity: entry?.quantity || '',
            currency: entry?.currency || null,
            tax: entry?.tax || '',
            taxValue: entry?.taxValue || '',
            taxValueRON: entry?.taxValueRON || '',
            price: entry?.price || '',
            priceRON: entry?.priceRON || '',
            exchangeRate: entry?.exchangeRate || '',
            stornoID: entry?.stornoID || '',
            total: entry?.total || '',
            totalRON: entry?.totalRON || ''
        },
        validationSchema: invoicesSchema.entries,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleEditEntry(values, entryIdx)
            handleReset()
        }
    })

    const handleClickAutocompleteNamePill = useCallback((value) => {
        if(!_.isNil(invoiceContract)) setFieldValue('name', `${values.name} ${value}`)
    }, [invoiceContract, setFieldValue, values.name])

    const isEntryCurrencyRON = useMemo(() => {
        return values.currency?.value === RON_CURRENCY_VALUE
    }, [values.currency?.value])

    useExchangeRateAndTotalLogic(
        isInvoiceCurrencyRON,
        isEntryCurrencyRON,
        invoiceExchangeRate,
        exchangeRates,
        values,
        setFieldValue
    )

    const modalTitle = useMemo(() => {
        const title = 'Editează'

        if(!_.isNil(entry?.name)) {
            return `${title} "${entry.name}"`
        }

        return title
    }, [entry?.name])

    return (
        <Modal
            open={open && !_.isNil(entry)}
            onClose={onClose}
            title={modalTitle}
        >
            <form className='edit-invoice-entry-form-container'>
                <Input
                    label='Denumire*'
                    value={values.name}
                    onChange={handleChange('name')}
                    name='name'
                    onBlur={handleBlur('name')}
                    frontendErrors={errors}
                    touched={touched.name}
                    fullWidth
                />
                {!_.isNil(invoiceContract) && (
                    <div className="autocomplete-invoice-entry-name-section">
                        <p>Completează câmpul denumire cu:</p>
                        <div className='autocomplete-buttons-row'>
                            {autocompleteEntryNameOptions.map(({label, value}) => (
                                <Button
                                    title={label}
                                    key={label}
                                    onClick={() => handleClickAutocompleteNamePill(value)}
                                    size='small'
                                />
                            ))}
                        </div>
                    </div>
                )}
                <div className="edit-invoice-entry-form-row">
                    <Select
                        label='U.M.*'
                        value={values.unit}
                        options={_.values(unitOptions)}
                        onChange={(value) => setFieldValue('unit', value)}
                        name='unit'
                        onBlur={handleBlur('unit')}
                        frontendErrors={errors}
                        touched={touched.unit}
                        fullWidth
                    />
                    <Input
                        label='Cantitate*'
                        value={values.quantity}
                        onChange={handleChange('quantity')}
                        name='quantity'
                        onBlur={handleBlur('quantity')}
                        frontendErrors={errors}
                        touched={touched.quantity}
                        fullWidth
                    />
                </div>
                <div className="edit-invoice-entry-form-row">
                    <Select
                        label='Moneda*'
                        value={values.currency}
                        options={currencies}
                        onChange={(e) => setFieldValue('currency', e)}
                        name='currency'
                        onBlur={handleBlur('currency')}
                        frontendErrors={errors}
                        touched={touched.currency}
                        fullWidth
                    />
                    {isUsingVat && <Select
                        label='Cotă TVA*'
                        value={values.tax}
                        options={_.values(taxPercentageOptions)}
                        onChange={(e) => setFieldValue('tax', e)}
                        name='tax'
                        onBlur={handleBlur('tax')}
                        frontendErrors={errors}
                        touched={touched.tax}
                        fullWidth
                    />}
                    <Input
                        label={priceInputLabel}
                        value={values.price}
                        onChange={handleChange('price')}
                        name='price'
                        onBlur={handleBlur('price')}
                        frontendErrors={errors}
                        touched={touched.price}
                        type='number'
                        fullWidth
                    />
                </div>
                {!_.isNil(values.currency?.value) && !isEntryCurrencyRON && isInvoiceCurrencyRON && (
                    <div className="edit-invoice-entry-form-row exchange-rate-container">
                        <Input
                            label='Curs*'
                            value={values.exchangeRate}
                            onChange={handleChange('exchangeRate')}
                            name='exchangeRate'
                            onBlur={handleBlur('exchangeRate')}
                            frontendErrors={errors}
                            touched={touched.exchangeRate}
                            disabled={!isInvoiceCurrencyRON}
                            type='number'
                            fullWidth
                        />
                        <Input
                            label='Preț fără TVA (RON)'
                            value={values.priceRON}
                            name='priceRON'
                            type='number'
                            disabled
                            fullWidth
                        />
                    </div>
                )}
                <Button
                    title='Salvează modificările'
                    onClick={handleSubmit}
                    color='secondary'
                    fullWidth
                />
            </form>
        </Modal>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EditInvoiceEntryModal)