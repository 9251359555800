import {Action} from 'avoapp-react-common/dist/redux'

export const ACTIONS = {
    OPEN_MODAL: new Action('OPEN_MODAL'),
    CLOSE_MODAL: new Action('CLOSE_MODAL')
}

export const modalTypes = {
    AVO_COLOR_PICKER: 'AVO_COLOR_PICKER',
    MARK_CLIENT_INACTIVE: 'MARK_CLIENT_INACTIVE',
    DELETE_CLIENT: 'DELETE_CLIENT',
    DELETE_CONTACT_PERSON: 'DELETE_CONTACT_PERSON',
    DELETE_CLIENT_ADDRESS: 'DELETE_CLIENT_ADDRESS',
    DELETE_CLIENT_BANK_ACCOUNT: 'DELETE_CLIENT_BANK_ACCOUNT',
    DELETE_CLIENT_NOTE: 'DELETE_CLIENT_NOTE',
    DELETE_CLIENT_GROUP: 'DELETE_CLIENT_GROUP',
    CREATE_LEGAL_OBJECT: 'CREATE_LEGAL_OBJECT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    MARK_PROJECT_INACTIVE: 'MARK_PROJECT_INACTIVE',
    DELETE_PROJECT_NOTE: 'DELETE_PROJECT_NOTE',
    DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
    DELETE_PARTY_SUBSCRIPTION: 'DELETE_PARTY_SUBSCRIPTION',
    DELETE_BPI_SUBSCRIPTION: 'DELETE_BPI_SUBSCRIPTION',
    ADD_TASK: 'ADD_TASK',
    ADD_EXPENSE: 'ADD_EXPENSE',
    ADD_EXPENSE_ALLOCATION: 'ADD_EXPENSE_ALLOCATION',
    ADD_EXPENSE_PAYMENT: 'ADD_EXPENSE_PAYMENT',
    ADD_EXPENSE_PAYMENT_ALLOCATION: 'ADD_EXPENSE_PAYMENT_ALLOCATION',
    ADD_INVOICE: 'ADD_INVOICE',
    EDIT_TASK: 'EDIT_TASK',
    EDIT_EXPENSE: 'EDIT_EXPENSE',
    SELECT_TASK: 'SELECT_TASK',
    ADD_TASK_TIME_LOG: 'ADD_TASK_TIME_LOG',
    EDIT_TASK_TIME_LOG: 'EDIT_TASK_TIME_LOG',
    CHECK_TASK_TIME_LOG: 'CHECK_TASK_TIME_LOG',
    DELETE_TASK_TIME_LOG: 'DELETE_TASK_TIME_LOG',
    DELETE_EXPENSE: 'DELETE_EXPENSE',
    DELETE_EXPENSE_PAYMENT: 'DELETE_EXPENSE_PAYMENT',
    ADD_DOCUMENT: 'ADD_DOCUMENT',
    CREATE_DOCUMENT: 'CREATE_DOCUMENT',
    UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
    UPLOAD_NEW_DOCUMENT: 'UPLOAD_NEW_DOCUMENT',
    EDIT_DOCUMENT_DETAILS: 'EDIT_DOCUMENT_DETAILS',
    DELETE_DOCUMENT: 'DELETE_DOCUMENT',
    DELETE_DOCUMENT_VERSION: 'DELETE_DOCUMENT_VERSION',
    DELETE_SIGNED_DOCUMENT: 'DELETE_SIGNED_DOCUMENT',
    ADD_DOCUMENT_TEMPLATE: 'ADD_DOCUMENT_TEMPLATE',
    DELETE_DOCUMENT_TEMPLATE: 'DELETE_DOCUMENT_TEMPLATE',
    ADD_DOCUMENT_SERIES: 'ADD_DOCUMENT_SERIES',
    EDIT_DOCUMENT_SERIES: 'EDIT_DOCUMENT_SERIES',
    DELETE_DOCUMENT_SERIES: 'DELETE_DOCUMENT_SERIES',
    TICKET_CREATED: 'TICKET_CREATED',
    FOUND_COMPANIES: 'FOUND_COMPANIES',
    REMOVE_OR_DELETE_DOCUMENT: 'REMOVE_OR_DELETE_DOCUMENT',
    ASSIGN_CONTRACT: 'ASSIGN_CONTRACT',
    INVALIDATE_CONTRACT: 'INVALIDATE_CONTRACT',
    REMOVE_OR_DELETE_CONTRACT: 'REMOVE_OR_DELETE_CONTRACT',
    EDIT_CONTRACT_SETTINGS_DISCLAIMER: 'EDIT_CONTRACT_SETTINGS_DISCLAIMER',
    DELETE_ADDENDUM: 'DELETE_ADDENDUM',
    INVALIDATE_ADDENDUM: 'INVALIDATE_ADDENDUM',
    REMOVE_OR_DELETE_ADDENDUM: 'REMOVE_OR_DELETE_ADDENDUM',
    DELETE_EMPOWERMENT: 'DELETE_EMPOWERMENT',
    DELETE_SIGNED_EMPOWERMENT: 'DELETE_SIGNED_EMPOWERMENT',
    DELETE_INVOICE: 'DELETE_INVOICE',
    DELETE_PROFORMA: 'DELETE_PROFORMA',
    OTP: 'OTP',
    RESET_ENTRIES_INFO: 'RESET_ENTRIES_INFO',
    ADD_DISCOUNT: 'ADD_DISCOUNT',
    ADD_STORNO: 'ADD_STORNO',
    ADD_INVOICE_PAYMENT: 'ADD_INVOICE_PAYMENT',
    ADD_INVOICE_PAYMENT_DISTRIBUTION: 'ADD_INVOICE_PAYMENT_DISTRIBUTION',
    DELETE_INVOICE_PAYMENT_DISTRIBUTION: 'DELETE_INVOICE_PAYMENT_DISTRIBUTION',
    DELETE_INVOICE_PAYMENT: 'DELETE_INVOICE_PAYMENT',
    GENERIC_WARNING_MODAL: 'GENERIC_WARNING_MODAL',
    AUTO_STOPPED_TASK_TIME_LOG: 'AUTO_STOPPED_TASK_TIME_LOG',
    ADD_MONITOR_DOSARE_SUBSCRIPTION: 'ADD_MONITOR_DOSARE_SUBSCRIPTION',
    ADD_MONITOR_BPI_SUBSCRIPTION: 'ADD_MONITOR_BPI_SUBSCRIPTION',
    ADD_MONITOR_DOSARE_MANUAL_SUBSCRIPTION: 'ADD_MONITOR_DOSARE_MANUAL_SUBSCRIPTION',
    INVOICE_EXTERNAL_SYNC_MODAL: 'INVOICE_EXTERNAL_SYNC_MODAL',
    INVOICE_EXTERNAL_MANUAL_RESOLVE_MODAL: 'INVOICE_EXTERNAL_MANUAL_RESOLVE_MODAL',
    ADD_PARTY_MODAL: 'ADD_PARTY_MODAL',
    DELETE_PARTY: 'DELETE_PARTY',
    ADD_HEARING_MODAL: 'ADD_HEARING_MODAL',
    EDIT_HEARING: 'EDIT_HEARING',
    DELETE_HEARING: 'DELETE_HEARING',
    ADD_APPEAL_MODAL: 'ADD_APPEAL_MODAL',
    DELETE_APPEAL: 'DELETE_APPEAL'
}

const initialState = {
    type: null
}

export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ACTIONS.OPEN_MODAL.main:
            return {
                type: action.payload.modalType,
                data: action.payload?.data || null
            }
        case ACTIONS.CLOSE_MODAL.main:
            return {
                type: null,
                data: null
            }
        default:
            return state
    }
}

export const openModal = (modalType, data=null) => ({
    type: ACTIONS.OPEN_MODAL.main,
    payload: {
        modalType: modalType,
        data: data
    }
})

export const closeModal = () => ({
    type: ACTIONS.CLOSE_MODAL.main
})
